import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

export class Snackbar {
  snacks = [];

  remove = (snack) => {
    const found = this.snacks.find(s => snack.message === s.message);
    this.snacks.remove(found);
  }

  add = (snack, timeout = 5000, options = {}) => {
    this.snacks.push({
      ...snack,
      variant: snack.variant || 'info',
      options
    });

    if (timeout) {
      setTimeout(() => {
        this.remove(snack)
      }, timeout);
    }
  }
}

decorate(Snackbar, {
  snacks: observable,
  add: action,
  remove: action,
});

export default createContext(new Snackbar());
