import { createContext } from 'react';
import { decorate, runInAction, action, observable } from 'mobx';
import { task } from 'mobx-task';
import { DataAgent } from 'agents';

export class Data {
  /** @var {array} */
  items = {};

  /**
   * Fetch localisation data for the given campaign.
   * 
   * @param {int} campaignId
   * @param {string} locale
   */
  fetch = task(async (campaignId, locale) => {
    const data = {};
    const response = await DataAgent.get(campaignId, locale).then(r => r.data);
    
    response.map(item => {
      const contents = {}
      
      Object.keys(item.contents).map(key => {
        contents[key] = item.contents[key] ? item.contents[key] : '';
        return key;
      });
      
      data[item.locale] = {
        ...item,
        contents,
      };

      return item;
    });

    if (data.ALL) {
      data.keys = Object.keys(data.ALL.contents);
    } else if (data.MASTER) {
      data.keys = Object.keys(data.MASTER.contents);
    }
    
    runInAction(() => {
      this.items = data;
    });

    return data;
  });

  /**
   * Update the given data set
   * 
   * @param {int} id
   * @param {object} contents
   */
  update = async (params) => {
    return await DataAgent.update(params).then(r => r.data);
  };

  /**
   * Clear the current set of items.
   */
  reset = () => {
    this.items = [];
  };

  /**
   * Upload new copy
   */
  upload = async (campaign, file) => {
    return await DataAgent.upload(campaign.id, file).then(r => r.data);
  }
}

decorate(Data, {
  reset: action,
  items: observable,
});

export default createContext(new Data());
