/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Virtuoso } from 'react-virtuoso'
import clsx from 'clsx';

const GROUPS = {
  SIEE: ['en_AU', 'de_AT', 'bg_BG', 'hr_HR', 'en_CY', 'cs_CZ', 'da_DK', 'fi_FI', 'fr_FR', 'de_DE', 'el_GR', 'hu_HU', 'en_IS', 'en_IN', 'en_IE', 'en_IL', 'it_IT', 'en_MT', 'nl_NL', 'en_NZ', 'no_NO', 'pl_PL', 'pt_PT', 'ro_RO', 'ru_RU', 'sk_SK', 'sl_SI', 'en_ZA', 'es_ES', 'sv_SE', 'tr_TR', 'uk_UA', 'en_GB', 'fr_CH', 'de_CH', 'it_CH', 'fr_BE', 'nl_BE', 'fr_LU', 'de_LU', 'ar_BH', 'ar_KW', 'ar_LB', 'ar_OM', 'ar_QA', 'ar_SA', 'ar_AE', 'en_BH', 'en_KW', 'en_LB', 'en_OM', 'en_QA', 'en_SA', 'en_AE'],
  SIEA: ['en_US', 'en_CA', 'fr_CA', 'es_AR', 'es_BO', 'es_CL', 'es_CO', 'es_CR', 'es_EC', 'es_SV', 'es_GT', 'es_HN', 'es_MX', 'es_NI', 'es_PA', 'es_PE', 'es_PY', 'es_UY', 'pt_BR'],
  SIEEA: ['en_AU', 'de_AT', 'bg_BG', 'hr_HR', 'en_CY', 'cs_CZ', 'da_DK', 'fi_FI', 'fr_FR', 'de_DE', 'el_GR', 'hu_HU', 'en_IS', 'en_IN', 'en_IE', 'en_IL', 'it_IT', 'en_MT', 'nl_NL', 'en_NZ', 'no_NO', 'pl_PL', 'pt_PT', 'ro_RO', 'ru_RU', 'sk_SK', 'sl_SI', 'en_ZA', 'es_ES', 'sv_SE', 'tr_TR', 'uk_UA', 'en_GB', 'fr_CH', 'de_CH', 'it_CH', 'fr_BE', 'nl_BE', 'fr_LU', 'de_LU', 'ar_BH', 'ar_KW', 'ar_LB', 'ar_OM', 'ar_QA', 'ar_SA', 'ar_AE', 'en_BH', 'en_KW', 'en_LB', 'en_OM', 'en_QA', 'en_SA', 'en_AE', 'en_US', 'en_CA', 'fr_CA', 'es_AR', 'es_BO', 'es_CL', 'es_CO', 'es_CR', 'es_EC', 'es_SV', 'es_GT', 'es_HN', 'es_MX', 'es_NI', 'es_PA', 'es_PE', 'es_PY', 'es_UY', 'pt_BR'],
  EFIGS: ['en_GB', 'es_ES', 'fr_FR', 'it_IT', 'de_DE'],
  EFIGSALL: ['en_GB', 'es_ES', 'fr_FR', 'fr_CH', 'fr_LU', 'fr_BE', 'it_IT', 'it_CH', 'de_DE', 'de_CH', 'de_LU', 'de_AT'],
  ME: ['ar_BH', 'ar_KW', 'ar_LB', 'ar_OM', 'ar_QA', 'ar_SA', 'ar_AE', 'en_BH', 'en_KW', 'en_LB', 'en_OM', 'en_QA', 'en_SA', 'en_AE'],
  GSA: ['de_DE', 'de_CH', 'de_AT'],
  LATAM: ['es_AR', 'es_BO', 'es_CL', 'es_CO', 'es_CR', 'es_EC', 'es_SV', 'es_GT', 'es_HN', 'es_MX', 'es_NI', 'es_PA', 'es_PE', 'es_PY', 'es_UY'],
  SIEJA: ['zh_HK', 'zh_CN', 'ch_HK', 'ch_TW', 'en_HK', 'en_ID', 'en_MY', 'en_SG', 'en_TH', 'ja_JP', 'ko_KR'],  
  SIEE_SIEA_SIEJA: ['en_US', 'en_CA', 'fr_CA', 'es_AR', 'es_BO', 'es_CL', 'es_CO', 'es_CR', 'es_EC', 'es_SV', 'es_GT', 'es_HN', 'es_MX', 'es_NI', 'es_PA', 'es_PE', 'es_PY', 'es_UY', 'pt_BR', 'en_AU', 'de_AT', 'bg_BG', 'hr_HR', 'en_CY', 'cs_CZ', 'da_DK', 'fi_FI', 'fr_FR', 'de_DE', 'el_GR', 'hu_HU', 'en_IS', 'en_IN', 'en_IE', 'en_IL', 'it_IT', 'en_MT', 'nl_NL', 'en_NZ', 'no_NO', 'pl_PL', 'pt_PT', 'ro_RO', 'ru_RU', 'sk_SK', 'sl_SI', 'en_ZA', 'es_ES', 'sv_SE', 'tr_TR', 'uk_UA', 'en_GB', 'fr_CH', 'de_CH', 'it_CH', 'fr_BE', 'nl_BE', 'fr_LU', 'de_LU', 'ar_BH', 'ar_KW', 'ar_LB', 'ar_OM', 'ar_QA', 'ar_SA', 'ar_AE', 'en_BH', 'en_KW', 'en_LB', 'en_OM', 'en_QA', 'en_SA', 'en_AE', 'zh_HK', 'zh_CN', 'ch_HK', 'ch_TW', 'en_HK', 'en_ID', 'en_MY', 'en_SG', 'en_TH', 'ja_JP', 'ko_KR'],
}

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  cardError: {
    borderColor: theme.palette.error.main,
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function intersection(a, b) {
  return a.filter(value => b.find(item => item.value === value) !== null);
}

function not(a, b) {
  return a.filter(value => b.find(item => item.value === value) === null);
}

// eslint-disable-next-line react/prop-types
const ListContainer = ({listRef, style, children}) => {
  return (
    <List
      component="div"
      dense
      ref={listRef}
      role="list"
      style={style}
    >
      {children}
    </List>
  );
};

const FormMultiSelect = props => {
  const {
    defaultValue,
    items,
    label,
    onChange,
    ...rest
  } = props;

  const classes = useStyles();
  const [checked, setChecked] = React.useState(defaultValue);
  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked, onChange]);

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(items.map(item => item.value));
    }
  };
  
  const selectGroup = (name) => {
    const group = GROUPS[name];
    setChecked(items.filter(item => group.includes(item.value)).map(item => item.value));
  }

  return (
    <React.Fragment>
      <Card
        className={clsx({
          [classes.card]: true,
          [classes.cardError]: rest.error,
        })}
      >
        <CardHeader
          avatar={
            <Checkbox
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              color="primary"
              disabled={items.length === 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              inputProps={{ 'aria-label': 'all items selected' }}
              onClick={handleToggleAll(items)}
            />
          }
          className={classes.cardHeader}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
          title={label}
        />

        {label.toLowerCase().indexOf('locales') >= 0 && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <Button
              color="primary"
              onClick={() => selectGroup('SIEE')}
            >SIEE</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('SIEA')}
            >SIEA</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('SIEEA')}
            >SIEE/SIEA</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('EFIGS')}
            >EFIGS</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('EFIGSALL')}
            >EFIGS ALL</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('ME')}
            >ME</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('GSA')}
            >GSA</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('LATAM')}
            >LATAM</Button>
            <Button
              color="primary"
              onClick={() => selectGroup('SIEJA')}
            >SIEJA</Button>

            <Button
              color="primary"
              onClick={() => selectGroup('SIEE_SIEA_SIEJA')}
            >SIEE/SIEA/SIEJA</Button>
          </React.Fragment>
        )}

        <Divider className={classes.divider} />
        <input
          name={`${rest.name}`}
          readOnly
          ref={rest.inputRef}
          type="hidden"
          value={checked.map(v => `${v}:${v}`).join('; ')}
        />
        <Virtuoso
          item={index => {
            const item = items[index];
            const { value, label } = item;
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
              <ListItem
                button
                key={value}
                onClick={handleToggle(item.value)}
                role="listitem"
                style={{margin: 0}}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item.value) !== -1}
                    color="primary"
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    tabIndex={-1}
                    value={item.value}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={label}
                />
              </ListItem>
            )
          }}
          // eslint-disable-next-line react/jsx-sort-props
          ListContainer={ListContainer}
          style={{height: '100%', minHeight: '230px'}}
          totalCount={items.length}
        />
      </Card>

      {rest.helperText && (
        <FormHelperText error>{rest.helperText}</FormHelperText>
      )}
    </React.Fragment>
  );
}

FormMultiSelect.defaultProps = {
  defaultValue: [],
};

FormMultiSelect.propTypes = {
  defaultValue: PropTypes.array,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default FormMultiSelect;
