/**
 * Extract and prepare the basic HTML content
 * for the given template.
 * 
 * @param {object} template 
 * @param {object} data 
 * 
 * @returns {string}
 */
export const tpl_get_base_html = (template, data) => {
  const configs = template.configurations;
  const head = configs.find(c => c.field_name === 'template_head').field_defaultvalue;
  const skeleton = configs.find(c => c.field_name === 'template_wrapper')
    .field_defaultvalue
    .replace('[[TEMPLATE_HEAD]]', `
      ${head}
      <base target="_blank" />
    `)
    .replace('[[TEMPLATE_HTML]]', '<div></div>');

  return skeleton;
}

/**
 * Extract and prepare the HTML for
 * the given module.
 * 
 * @param {object} item 
 * @param {bool} usePlaceholder
 * @param {object} data
 * @param {array} assets
 * @param {array} settings
 * @param {string} locale
 * @returns {string}
 */
export const tpl_get_module_html = (item, usePlaceholder = false, data, assets = [], locale = 'ALL', settings = []) => {
  try {
    let html = item.html || '';
    let css = item.css || '';
    let elements = {};
    
    try {
      const wrapperName = item.configurations.find(c => c.field_name === 'module_wrapper').field_defaultvalue;
      const holders = item.wrapper_placeholders.module_placeholder;
      let wrapper = item.wrapper_placeholders.module_wrappers[wrapperName];

      Object.keys(holders).forEach(key => {
        const value = item.configurations.find(c => c.field_name === key).field_defaultvalue;
        let sanitizedSelector = holders[key].replace('[[', '\\[\\[').replace(']]', '\\]\\]');
        let regex = new RegExp(sanitizedSelector, 'g');
        wrapper = wrapper.replace(regex, value ? value : '');
      });

      html = wrapper.replace(wrapperName, html);
    // eslint-disable-next-line no-empty
    } catch (e) {
    }

    const variables = {
      '{{module_id}}': item.id,
      '{{module_slug}}': item.slug,
      '{{module_identifier}}': item.identifier,
    };

    item.elements.forEach(element => {
      let content = element[usePlaceholder ? 'placeholder' : 'template'];

      if (!content) {
        return '';
      }

      element.attributes.forEach(attribute => {
        let regex = new RegExp(`{{${attribute.field_name}}}`, 'g');
        content = content.replace(regex, attribute.field_defaultvalue);
      });

      elements[element.selector] = content;
    });

    Object.keys(elements).map(selector => {
      let sanitizedSelector = selector.replace('[[', '\\[\\[').replace(']]', '\\]\\]');
      let regex = new RegExp(sanitizedSelector, 'g');
      html = html.replace(regex, elements[selector]);
      return selector;
    });

    Object.keys(variables).forEach(key => {
      let regex = new RegExp(key, 'g');
      html = html.replace(regex, variables[key]);
    });

    if (data) {
      Object.keys(data.contents).map(key => {
        let regex = new RegExp(`\\*\\*copy\\.${key}\\*\\*`, 'g');
        html = html.replace(regex, data.contents[key]);
        return key;
      });
    }

    if (settings) {
      settings.forEach(setting => {
        const regex = new RegExp(`\\*\\*global\\.${setting.name}\\*\\*`, 'g');
        const value = setting.locales[locale] ? setting.locales[locale] : setting.value;
        html = html.replace(regex, value);
      });
    }

    if (assets) {
      assets.forEach(asset => {
        const { name, url } = asset;
        html = html.replace(new RegExp(name, 'g'), url);
      });
    }

    // todo:
    // extract the CSS and make sure is unique? prod only?
    return html + css;
  } catch (e) {
    console.log(e);
    return '';
  }
}
