import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { FormSelect } from 'components';
import FormMultiSelect from 'components/FormMultiSelect';
import { str_capitalize_first, str_of_values_to_array } from 'helpers';
import { toJS } from 'mobx';
import FormRepeater from 'components/FormRepeater';

const FormDynamicField = props => {
  const {
    field,
    input,
    namePrefix,
    onChange,
  } = props;

  const render = () => {
    const required = !!field.field_required;
    const inputDetails = input(`${namePrefix}${field.field_name}`, { required });
    const fieldType = field.field_type.toLowerCase();

    switch(fieldType) {
      case 'text': return (
        <TextField
          {...inputDetails}
          defaultValue={field.field_defaultvalue}
          label={field.field_title}
        />
      );

      case 'password': return (
        <TextField
          {...inputDetails}
          defaultValue={field.field_defaultvalue}
          label={field.field_title}
          type="password"
        />
      );

      case 'textarea': return (
        <TextField
          {...inputDetails}
          defaultValue={field.field_defaultvalue}
          label={field.field_title}
          multiline
          rows={2}
          rowsMax={4}
        />
      );

      case 'select': {
        const values = str_of_values_to_array(field.field_value);

        return (
          <FormSelect
            defaultValue={field.field_defaultvalue}
            label={field.field_title}
            onChange={onChange}
            placeholder={field.field_placeholder || ''}
            required={required}
            values={values}
            {...inputDetails}
          />
        )
      }

      case 'checkbox': {
        console.log(toJS(field))
        return <div>TODO - CHECKBOX</div>
      }

      case 'repeater': {
        return (
          <FormRepeater
            defaultValue={field.field_defaultvalue}
            disabled={inputDetails.disabled}
            fieldName={field.field_name}
            input={input}
            label={field.field_title ? field.field_title : str_capitalize_first(field.field_name)}
            required={!!field.field_required}
          />
        )
      }

      case 'hidden': {
        return null
      }
      
      case 'multi-select': {
        const items = str_of_values_to_array(field.field_value)
        const value = str_of_values_to_array(field.field_defaultvalue).map(item => item.value)

        return (
          <FormMultiSelect
            defaultValue={value}
            items={items}
            label={field.field_title ? field.field_title : str_capitalize_first(field.field_name)}
            {...inputDetails}
          />
        )
      }

      default: return <div />
    }
  }

  return render();
}

FormDynamicField.defaultProps = {
  namePrefix: '',
};

FormDynamicField.propTypes = {
  field: PropTypes.object.isRequired,
  input: PropTypes.func.isRequired,
  namePrefix: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormDynamicField;
