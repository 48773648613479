export default {

  dashboard: '/',
  login: '/sign-in',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  users: '/users',
  users_edit: '/users/:id/edit',
  roles: '/roles',
  roles_edit: '/roles/:id/edit',
  campaigns: '/campaigns',
  campaigns_show: '/campaigns/:id',
  campaigns_edit: '/campaigns/:id/edit',
  
  territory_approval: '/campaigns/:id/review/',
  territory_approval_chat: '/campaigns/:id/review/chat',

  templates: '/templates',
  templates_edit: '/templates/:id/edit',
  templates_preview: '/templates/:id/preview',

  global_settings: '/global-settings',
  global_settings_show: '/global-settings/:id',
  global_settings_edit: '/global-settings/:id/edit',

  input_fields: '/input-fields',
  input_field_show: '/input-fields/:id',
  input_field_edit: '/input-fields/:id/edit',

  elements: '/elements',
  elements_show: '/elements/:id',
  element_edit: '/elements/:id/edit',

  modules: '/modules',
  modules_show: '/modules/:id',
  module_edit: '/modules/:id/edit',

  configurations: '/configurations',
  configuration_show: '/configurations/:id',
  configuration_edit: '/configurations/:id/edit',

};
