import { createContext } from 'react';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { ElementAgent } from 'agents';

export class Element {
  elements = [];
  pagination = null;

  storeElement = task(async (title) => {
    return await ElementAgent.storeElement({title})
      .then(response => {
        const element = response.data.data
        runInAction(() => {
          this.elements.unshift(element)
        });
        return response;
      });
  });

  updateElement = task(async (data) => {
    await ElementAgent.updateElement(data)
  });

  find = task(async (id) => {
    const item = await ElementAgent.find(id).then(r => r.data);
    return item;
  })

  fetch = task(async (page = 1, active = null) => {
    const response = await ElementAgent.get(page, active);
    
    runInAction(() => {
      this.elements = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.elements = [];
  }
}

decorate(Element, {
  elements: observable,
  pagination: observable,
});

export default createContext(new Element());
