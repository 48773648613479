import React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';

import PropTypes from 'prop-types';

const Confirmation = ({
  labelAgree,
  labelCancel,
  open,
  title,
  description,
  onConfirm,
  onCancel,
  submitting,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {submitting && (
          <CircularProgress size={20} />
        )}

        <Button
          color="primary"
          disabled={submitting}
          onClick={onConfirm}
        >
          {labelAgree}
        </Button>
        <Button
          color="primary"
          disabled={submitting}
          onClick={onCancel}
        >
          {labelCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirmation.defaultProps = {
  labelAgree: 'YES, I AGREE',
  labelCancel: 'CANCEL'
}

Confirmation.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  labelAgree: PropTypes.string,
  labelCancel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Confirmation;
