import { createContext } from 'react';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { GlobalSettingAgent } from 'agents';

export class GlobalSetting {
  item = null;
  items = [];
  pagination = null;

  store = task(async (data) => {
    return await GlobalSettingAgent.store(data)
      .then(response => {
        const globalsetting = response.data.data
        runInAction(() => {
          this.items.unshift(globalsetting)
        });
        return response;
      });
  });

  show = (id) => {
    return this.items.find(e => e.id === parseInt(id))
  };

  find = task(async (id) => {
    const response = await GlobalSettingAgent.find(id);
    runInAction(() => {
      this.item = response.data;
    });
  });

  update = task(async (data) => {
    return await GlobalSettingAgent.update(data)
  });

  fetch = task(async (page) => {
    const response = await GlobalSettingAgent.get(page);
    
    runInAction(() => {
      this.items = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.items = [];
  }
}

decorate(GlobalSetting, {
  item: observable,
  items: observable,
  pagination: observable,
});

export default createContext(new GlobalSetting());
