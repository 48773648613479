import Client from './client';

export default {
  all: () => Client.get('/users/all'),
  find: id => Client.get(`/users/${id}`),
  fetch: page => Client.get(`/users?page=${page}`),
  store: data => Client.post('/users', data),
  update: data => Client.put(`/users/${data.id}`, data),
  toggleActive: (user, status) => Client.put(`/users/${user.id}`, {
    active: status,
    email: user.email,
    locales: user.locales,
    role_id: user.role.id,
    first_name: user.first_name,
    last_name: user.last_name,
  }),
  destroy: id => Client.delete(`/users/${id}`),
}
