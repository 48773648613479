import Client from './client';

export default {
  all: () => Client.get('/roles/all'),
  find: id => Client.get(`/roles/${id}`),
  fetch: page => Client.get(`/roles?page=${page}`),
  store: data => Client.post('/roles', data),
  update: data => Client.put(`/roles/${data.id}`, data),
  destroy: id => Client.delete(`/roles/${id}`),
}
