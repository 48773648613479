import { createContext } from 'react';
import { decorate, observable, runInAction, action } from 'mobx';
import { task } from 'mobx-task';
import { ModuleAgent } from 'agents';
import { arr_inline_search } from 'helpers';

export class Module {
  allModules = [];
  modules = [];
  item = null;
  pagination = null;

  store = task(async (name) => {
    return await ModuleAgent.store({name})
      .then(response => {
        const module = response.data.data
        runInAction(() => {
          this.modules.unshift(module)
        });
        return response;
      });
  });

  update = task(async (data, thumbnail) => {
    const response = await ModuleAgent.update(data).then(response => response.data.data);
    
    if (thumbnail) {
      const thumb = await ModuleAgent.uploadThumbnail(data.id, thumbnail);
      response.thumbnail_url = thumb.data.thumbnail_url;
    }
    
    return response
  });

  all = task(async () => {
    const response = await ModuleAgent.all();
    
    runInAction(() => {
      this.allModules = response.data;
    });

    return response;
  });

  fetch = task(async (page) => {
    const response = await ModuleAgent.get(page);
    
    runInAction(() => {
      this.modules = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  find = task(async (id) => {
    const item = await ModuleAgent.find(id).then(r => r.data);
    
    runInAction(() => {
      this.item = item;
    });
    
    return item;
  })

  preview = task(async (mod, placeholder) => {
    return await ModuleAgent.preview(placeholder ? mod.preview_link_placeholder : mod.preview_link)
  });

  destroy = (id) => {
    const found = this.modules.find(s => parseInt(id) === s.id);
    this.modules.remove(found);
    ModuleAgent.destroy(id)
  };

  search = (query) => {
    return arr_inline_search(this.modules, query, [
      'name', 'description'
    ]);
  }

  findModule = (id) => {
    return this.modules.find(m => m.id === parseInt(id))
  };

  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.modules = [];
  };
}

decorate(Module, {
  allModules: observable,
  modules: observable,
  pagination: observable,
  destroy: action,
  item: observable,
});

export default createContext(new Module());
