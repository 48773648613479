import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthStore, AppStore } from 'stores';
import StoreFetcher from 'components/StoreFetcher';

const AppLoader = props => {
  const { children } = props;
  const authStore = useContext(AuthStore);
  const appStore = useContext(AppStore);

  useEffect(() => {
    authStore.fetch(appStore);
  }, [authStore, appStore]);

  if (!authStore.token) {
    return children;
  }

  return (
    <StoreFetcher
      fetchers={[
        authStore.fetch,
      ]}
      needsPadding={false}
    >
      {children}
    </StoreFetcher>
  );
};

AppLoader.propTypes = {
  children: PropTypes.node,
};

export default AppLoader;

