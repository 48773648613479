/**
 * Generate a random UUID.
 */
export const str_create_uuid = function () {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    // eslint-disable-next-line no-mixed-operators
    return (c==='x' ? r :(r&0x3|0x8)).toString(16);
  });
  
  return uuid;
}

/**
 * Given a string created by MultiSelect
 * generates an array with the values.
 * 
 * @param string string 
 */
export const str_multiselect_to_array = string => {
  return string.split('; ').map(l => l.split(':')[0]);
};

/**
 * Capitalize the first letter of the given string.
 * @param string string 
 */
export const str_capitalize_first = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const str_of_values_to_array = function(string) {
  const items = [];

  if (!string || !string.length) {
    return items;
  } else if (Array.isArray(string)) {
    string.forEach(val => {
      if (val.value && val.label) {
        items.push(val);
      } else if (val.indexOf(':') > 0) {
        const parts = val.split(':');
        items.push({
          value: parts[0].trim(),
          label: parts[1].trim(),
        });
      } else {
        items.push({
          value: val,
          label: val,
        });
      }
    });
  } else {
    string.trim().split('; ').map(item => {
      if (item) {
        const parts = item.split(':')
        if (parts[0] && parts[1]) {
          items.push({
            value: parts[1].trim(),
            label: parts[0].trim()
          })
        }
      }
      return item;
    });
  }

  return items;
}
