import React from 'react';
import {
  People as IconPeople,
  Email as IconEmail,
  LineStyle as IconLineStyle,
  Security as IconSecurity,
  Apps as IconApps,
  Input as IconInput,
  Tune as IconTune,
  Settings as IconSettings,
} from '@material-ui/icons';
import { PATHS } from 'config';

const menu = [
  {
    pages: [
      {
        permissions: ['campaigns'],
        title: 'Campaigns',
        href: PATHS.campaigns,
        icon: <IconEmail />,
      },
      {
        permissions: ['modules'],
        title: 'Modules',
        href: PATHS.modules,
        icon: <IconLineStyle />,
      },
      {
        permissions: ['elements'],
        title: 'Elements',
        href: PATHS.elements,
        icon: <IconApps />,
      },
      {
        permissions: ['inputfields'],
        title: 'Input Fields',
        href: PATHS.input_fields,
        icon: <IconInput />,
      },
      {
        permissions: ['configurations'],
        title: 'Configurations',
        href: PATHS.configurations,
        icon: <IconSettings />,
      },
      {
        permissions: ['globalsettings'],
        title: 'Global Settings',
        href: PATHS.global_settings,
        icon: <IconTune />,
      },
    ]
  },
];

// Admin section
menu.push({
  header: 'Admin',
  permissions: ['users', 'roles'],
  pages: [
    {
      permissions: ['users'],
      title: 'Users',
      href: PATHS.users,
      icon: <IconPeople />
    },
    {
      permissions: ['roles'],
      title: 'Roles',
      href: PATHS.roles,
      icon: <IconSecurity />
    }
  ]
});

// User specific items
menu.push({
  header: 'Profile',
  pages: [
    {
      title: 'Logout',
      href: PATHS.logout,
      icon: <IconInput />
    },
  ]
});

export default menu;
