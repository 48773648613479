import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const SearchableTypography = ({ query, children, ...rest }) => {
  if (!query) {
    return <Typography {...rest}>{children}</Typography>;
  }

  return (
    <Typography
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: query ? children.replace(new RegExp(query, 'gi'), (match) => `<mark>${match}</mark>`) : children
      }}
      {...rest}
    />
  );
};

SearchableTypography.propTypes = {
  children: PropTypes.string.isRequired,
  query: PropTypes.string,
};

export default SearchableTypography;
