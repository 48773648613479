import React, { useContext } from 'react';
import { useBoolean } from 'hooks';
import { SnackbarStore } from 'stores';
import useFormHook from 'react-hook-form';
import { Button } from '@material-ui/core';

const defaultErrors = {
  required: 'This field is required'
}

const retrieveInputError = (name, errors) => {
  const error = errors[name]
  if (!error) {
    return null
  }
  return error.message || defaultErrors[error.type] || 'There is an error with this field'
}

export default ({ messageSuccess, snackActionLabel, snackAction, snackOptions = {}, snackTimeout = 5000, inputMargin = 'dense', messageError = 'Something went wrong, please try again.', onPostData, onError, onComplete, ...props}) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    setValue,
    setError,
    formState,
    triggerValidation,
  } = useFormHook(props)
  const snackbarStore = useContext(SnackbarStore);
  const loading = useBoolean(false);

  return {
    register,
    formState,
    basicSubmit: async e => {
      e.preventDefault();
      e.stopPropagation();
      
      loading.setTrue()

      const result = await triggerValidation()
      loading.setFalse()
      
      if (result) {
        onComplete(getValues({
          nest: true
        }));
      } else {
        snackbarStore.add({
          message: 'Please, make sure there are no errors.',
          variant: 'error',
        });
      }
    },
    input: (name, rules = {}) => {
      const error = retrieveInputError(name, errors);
      const attrs = {
        name,
        error: !!error,
        helperText: error,
        fullWidth: true,
        margin: inputMargin,
        disabled: loading.value,
        inputRef: register(rules),
        variant: 'outlined',
      };

      return attrs;
    },
    watch,
    errors,
    loading,
    getValues,
    setValue,
    setError,
    onSubmit: handleSubmit(async (data) => {
      loading.setTrue()

      try {
        const response = await onPostData(data);

        if (messageSuccess) {
          if (snackAction) {
            snackOptions.action = (
              <Button
                color="secondary"
                onClick={() => {
                  snackAction(response);
                }}
                size="small"
              >
                {snackActionLabel ? snackActionLabel : 'Take me there'}
              </Button>
            )
          }

          snackbarStore.add({
            message: messageSuccess,
            variant: 'success',
          }, snackTimeout, snackOptions);
        }

        loading.setFalse();

        onComplete && onComplete(response);
      } catch (e) {
        loading.setFalse();
        let message = messageError;
        // console.log(e)
        if (e.response && e.response.status === 422) {
          message = 'Please, make sure to fix the errors in red.';
        }
        
        try {
          // eslint-disable-next-line no-unused-vars
          for (let [key, value] of Object.entries(e.response.data.errors)) {
            let name = key;
            
            if (key.indexOf('.') > 0) {
              let parts = key.split('.');
              name = `${parts[0]}[${parts[1]}]`;
            }

            setError(name, 'laravel-error', value)
          }

          onError && onError(e.response)
          // eslint-disable-next-line no-empty
        } catch (e) {}

        snackbarStore.add({
          message: message,
          variant: 'error',
        });
      }
    }, [loading, snackbarStore, messageSuccess])
  }
};
