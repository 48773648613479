export const obj_extract_dotted_errors = response => {
  const errors = {};

  Object.keys(response).forEach(key => {
    key.split('.').reduce((current, value) => {
      const [acc, prev] = Array.isArray(current) ? current : [current, value];

      if (!errors[acc]) {
        errors[acc] = [];
      }

      if (!isNaN(value) && !errors[acc][value]) {
        errors[acc][value] = {};
      } else if (isNaN(value)) {
        const partial = `${acc}.${prev}.`
        errors[acc][prev][value] = response[key][0].replace(new RegExp(partial, 'g'), '')
      }

      return [acc, value];
    })
  });

  return errors;
};

export const get_obj_keys = function (obj, only = []) {
  let arr = [];
  Object.keys(obj).map((k) => {
    if(only.includes(k)){
      arr.push(k);
    }
    return null;
  })
  return arr;
}
