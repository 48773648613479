import { createContext } from 'react';
import { decorate, observable, action, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { AuthAgent } from 'agents';

export class Auth {
  token = window.localStorage.getItem('jwt');
  user = null;
  appStore = null;

  logout = task(async () => {
    await AuthAgent.logout();
    runInAction(() => {
      window.localStorage.setItem('jwt', null);
      this.user = null;
      this.token = null;
    });
  });

  forgotPassword = async (email) => {
    return await AuthAgent.forgotPassword(email);
  };

  resetPassword = async (email, token, password, passwordConfirmation) => {
    return await AuthAgent.resetPassword(email, token, password, passwordConfirmation);
  };

  login = async (email, password) => {
    try {
      const response = await AuthAgent.login(email, password).then(r => r.data);
      this.token = response.token;
      this.user = response.user;
      window.localStorage.setItem('jwt', this.token);

      await this.appStore.boot();
    } catch (e) {
      throw e.response.data;
    }
  }

  fetch = task(async (appStore) => {
    runInAction(() => {
      this.appStore = appStore;
    });
    
    if (!this.token) {
      return;
    }

    try {
      const user = await AuthAgent.profile().then(r => r.data);
      
      runInAction(() => {
        this.user = user;
      });

      await this.appStore.boot();

      return user;
    } catch (e) {
      runInAction(() => {
        window.localStorage.setItem('jwt', null);
        this.user = null;
        this.token = null;
      });

      return false;
    }
  });
}

decorate(Auth, {
  user: observable,
  users: observable,
  login: action,
});

export const authStore = new Auth();
export default createContext(authStore);
