import { createContext } from 'react';
import { decorate, observable, runInAction, action } from 'mobx';
import { CampaignAgent } from 'agents';
import { task } from 'mobx-task';

export class Campaign {
  campaigns = [];
  pagination = null;
  campaign = null;

  find = task(async (id) => {
    return await CampaignAgent.find(id)
      .then((response) => {
        runInAction(() => {
          this.campaign = response.data.campaign
        });
      });
  });

  update = async attrs => {
    const response = await CampaignAgent.update(attrs).then(r => r.data);
    const { data } = response;

    runInAction(() => {
      this.campaign = data;
    });

    return data;
  };

  /**
   * Pull Categories and Sub categories
   * from the API.
   */
  categories = task(async () => {
    return await CampaignAgent.categories().then(r => r.data);
  });

  duplicate = async (attrs) => {
    const response = await CampaignAgent.duplicate(attrs);
    return response.data;
  }

  store = async (attrs) => {
    const response = await CampaignAgent.store(attrs);
    const data = response.data.data;

    runInAction(() => {
      this.campaigns.unshift(data);
    });

    return data;
  };

  fetch = task(async (page, keyword) => {
    const response = await CampaignAgent.get(page, keyword);

    runInAction(() => {
      this.campaigns = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  find = task(async (id) => {
    const data = await CampaignAgent.find(id).then(r => r.data);
    
    runInAction(() => {
      this.campaign = data;
    });

    return data;
  });

  approve = task(async (id, locales) => {
    const response = await CampaignAgent.approve(id, locales).then(r => r.data);
    return response;
  });

  updateStepStatus = async (id, step, status, extra = {}) => {
    const response = await CampaignAgent.updateStepStatus(id, step, status, extra).then(r => r.data);
    const { data } = response;

    runInAction(() => {
      this.campaign = {
        ...this.campaign,
        steps: data.steps,
      };
    });

    return data;
  }

  destroy = async (id) => {
    await CampaignAgent.destroy(id);
  };

  /**
   * Reset the current set of items
   */
  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.campaigns = [];
  }
}

decorate(Campaign, {
  campaigns: observable,
  campaign: observable,
  pagination: observable,
  loading: observable,
  destroy: action,
});

export default createContext(new Campaign());
