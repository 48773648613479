import React, { useEffect } from 'react'
import nprogress from 'nprogress';

export default function SlimProgress() {
  useEffect(() => {
    nprogress.start();
    return () => {
      nprogress.done();
    };
  }, []);

  return <div />
}
