import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormBox from 'components/FormBox';
import { FormGrid, FormColumn } from 'components';
import FormDynamicField from 'components/FormDynamicField';

const FormAuto = ({ values, input, schema, sources }) => {
  const [linkedSources, setLinkedSources] = useState({});
  return (
    <React.Fragment>
      {schema.map((box, i) => {
        return (
          <FormBox
            key={i}
            marginTop={i > 0}
            title={box.title}
          >
            <FormGrid>
              {box.fields.map((field, ii) => {
                const dynamicField = {};
                const columnProps = {};
                const fieldProps = {};

                if (field.size) {
                  columnProps.size = field.size;
                }

                if (field.linked_to) {
                  fieldProps.onChange = (e) => {
                    const linked = box.fields.find(f => f.name === field.linked_to);
                    
                    if (!linked) {
                      return;
                    }

                    const { value } = e.target;
                    setLinkedSources(s => {
                      return {
                        ...s,
                        [linked.source]: value ? sources[linked.source][value] : ''
                      }
                    })
                  }

                  if (values[field.name]) {
                    const linked = box.fields.find(f => f.name === field.linked_to);
                    const value = values[field.name];
                    if (linked && sources[linked.source][value] && !linkedSources[linked.source]) {
                      setLinkedSources(s => {
                        return {
                          ...s,
                          [linked.source]: sources[linked.source][value]
                        }
                      })
                    }
                  }
                }
                
                Object.keys(field).map(key => {
                  dynamicField[`field_${key}`] = field[key];
                  return key;
                });

                if (field.source) {
                  dynamicField.field_value = linkedSources[field.source] ? linkedSources[field.source] : sources[field.source];
                  // ToDo: this doesn't seem to be working...
                  if (field.required_if_has_source && dynamicField.field_value.length) {
                    dynamicField.field_required = true;
                  }
                }

                if (field.type === 'repeater') {
                  fieldProps.namePrefix = 'repeater_';
                }

                if (values && values[field.name]) {
                  dynamicField.field_defaultvalue = values[field.name];
                }
                
                return (
                  <FormColumn
                    key={`${field.name}-${ii}`}
                    {...columnProps}
                  >
                    <FormDynamicField
                      field={dynamicField}
                      input={input}
                      {...fieldProps}
                    />
                  </FormColumn>
                );
              })}
            </FormGrid>
          </FormBox>
        )
      })}
    </React.Fragment>
  );
};

FormAuto.defaultProps = {
  sources: {},
  values: {},
};

FormAuto.propTypes = {
  input: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  sources: PropTypes.object,
  values: PropTypes.object,
};

export default FormAuto;
