import React from 'react';
import { CircularProgress  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: props => props.centered ? 'center' : 'flex-start',
    justifyContent: 'center',
    margin: theme.spacing(3)
  },
}));

const StatusLoading = props => {
  const classes = useStyles(props);

  return <div className={classes.root}>
    <CircularProgress />
  </div>
};

StatusLoading.defaultProps = {
  centered: true,
};

StatusLoading.propTypes = {
  centered: PropTypes.bool,
}

export default StatusLoading;
