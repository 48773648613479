export const CAMPAIGN_STATUS = {
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started',
  DONE: 'done',
};

export const CAMPAIGN_STEPS = [
  {
    id: 'DEVELOPMENT',
    name: 'Development',
  },
  {
    id: 'TERRITORY_APPROVAL',
    name: 'Territory Approval',
  },
  {
    id: 'SFMC_UPLOAD',
    name: 'Uploaded to SFMC',
  },
  // {
  //   id: 'COMPLETE',
  //   name: 'Completed',
  // }
];
