import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import SimplePagination from 'components/SimplePagination';
import ActionsRow from 'components/ActionsRow';
import Error204 from 'views/Errors/204';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  alignRight: {
    textAlign: 'right',
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
}));

const ListTable = props => {
  const {
    actions,
    actionsBody,
    className,
    pagination,
    data,
    head,
    body,
    ...rest
  } = props;
  const classes = useStyles();

  if (!data || !data.length) {
    return (
      <Error204 />
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {head}
                  {actions || actionsBody ? <TableCell className={classes.alignRight}>Actions</TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                  >
                    {body(item)}

                    {actionsBody ? (
                      <TableCell className={classes.alignRight}>
                        <ActionsRow
                          actions={actionsBody(item)}
                          item={item}
                        />
                      </TableCell>
                    ) : null}

                    {actions ? (
                      <TableCell className={classes.alignRight}>
                        <ActionsRow
                          actions={actions}
                          item={item}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        {pagination && (
          <SimplePagination {...pagination} />
        )}
      </CardContent>
    </Card>
  );
};

ListTable.defaultProps = {
  actions: null,
}

ListTable.propTypes = {
  actions: PropTypes.array,
  actionsBody: PropTypes.func,
  body: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  head: PropTypes.object.isRequired,
  pagination: PropTypes.shape(SimplePagination.propTypes)
};

export default ListTable;
