import { createContext } from 'react';
import { decorate, runInAction, observable } from 'mobx';
import { task } from 'mobx-task';
import { TemplateAgent } from 'agents';

export class Template {
  item = null;

  update = task(async (data) => {
    const response = await TemplateAgent.update(data).then(r => r.data);

    runInAction(() => {
      this.item.modules = response.data.modules;
    });

    return response;
  });

  find = task(async (id) => {
    const item = await TemplateAgent.find(id).then(r => r.data);
    
    runInAction(() => {
      this.item = item;
    });
    
    return item;
  });

  preview = task(async (template, placeholder) => {
    return await TemplateAgent
      .preview(placeholder ? template.preview_link_placeholder : template.preview_link)
      .then(response => response.data);
  });
  
  previewGuest = task(async (id, placeholder) => {
    return await TemplateAgent
      .previewGuest(id, placeholder)
      .then(response => response.data);
  });
}

decorate(Template, {
  item: observable,
});

export default createContext(new Template());
