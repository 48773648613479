import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Divider,
  CardContent,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0,
  },
  noMarginRight: {
    marginRight: 0
  },
  textWithActions: {
    paddingRight: '50px',
  },
  itemWithError: {
    backgroundColor: '#ff000024'
  }
}))

const ListBasic = ({ items, errors, fields, emptyMessage, onEditClick, onDeleteClick }) => {
  const classes = useStyles();
  
  return (
    <List className={classes.noPadding}>
      {!items.length && (
        <CardContent>{emptyMessage}</CardContent>
      )}
      {items.map((item, i) => {
        return (
          <React.Fragment key={`attr-${i}-${item.id}`}>
            {i > 0 && <Divider />}
            <ListItem
              className={clsx({
                [classes.itemWithError]: errors[i]
              })}
            >
              <ListItemText
                className={onEditClick && onDeleteClick ? classes.textWithActions : null}
                primary={item[fields.title]}
                secondary={item[fields.description]}
              />
              <ListItemSecondaryAction>
                {onEditClick && (
                  <IconButton
                    className={clsx(onDeleteClick ? classes.noMarginRight : {})}
                    edge="end"
                    onClick={() => onEditClick(item, i)}
                  >
                    <EditIcon />
                  </IconButton>
                )}

                {onDeleteClick && (
                  <IconButton
                    edge="end"
                    onClick={() => onDeleteClick(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        )
      })}
    </List>
  );
};

ListBasic.defaultProps = {
  fields: {
    title: 'title',
    description: 'description',
  },
  errors: [],
}

ListBasic.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  errors: PropTypes.array,
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  items: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default observer(ListBasic);
