import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button, Typography  } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const SimplePagination = props => {
  const {
    current_page,
    loading,
    onChange,
    per_page,
    total,
  } = props

  const classes = useStyles();
  const totalPages = Math.ceil(total / per_page)

  const right = []
  const left = []

  if (current_page > 1) {
    left.push(current_page - 1);
  }

  if (current_page < totalPages) {
    right.push(current_page + 1);
  }

  if (!left.length && (current_page + 1) < totalPages) {
    right.push(current_page + 2);
  }

  return (
    <div className={classes.root}>
      
      <div className={classes.info}>
        <Typography variant="body2">Page {current_page} of {totalPages}</Typography>
      </div>

      <div className={classes.links}>
        {loading && (
          <CircularProgress
            className={classes.loader}
            size={20}
          />
        )}

        <Button
          className={classes.link}
          onClick={() => current_page > 1 ? onChange(current_page - 1) : {}}
        >
          <ChevronLeftIcon />
        </Button>

        {left.map(page => (
          <Button
            className={classes.link}
            key={`left-page-${page}`}
            onClick={() => onChange(page)}
          >
            {page}
          </Button>
        ))}

        <span className={clsx(classes.link, classes.linkCurrent)}>{current_page}</span>
        
        {right.map(page => (
          <Button
            className={classes.link}
            key={`right-page-${page}`}
            onClick={() => onChange(page)}
          >
            {page}
          </Button>
        ))}

        <Button
          className={classes.link}
          onClick={() => current_page < totalPages ? onChange(current_page + 1) : {}}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  )
}

SimplePagination.propTypes = {
  current_page: PropTypes.number,
  from: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  per_page: PropTypes.number,
  total: PropTypes.number,
}

export default SimplePagination;
