import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { SnackbarStore } from 'stores';
import { Snackbar } from '@material-ui/core';
import { SnackContent } from './components';

const Snacks = () => {
  const snackbarStore = useContext(SnackbarStore);
  
  const handleClose = (snack) => {
    snackbarStore.remove(snack)
  }

  return (
    snackbarStore.snacks.map((snack, i) => {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          key={`${i}-${snack.message}`}
          onClose={() => handleClose(snack)}
          open
        >
          <SnackContent
            message={snack.message}
            onClose={() => handleClose(snack)}
            variant={snack.variant}
            {...snack.options}
          />
        </Snackbar>
      )
    })
  )
}

export default observer(Snacks)
