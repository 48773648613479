import { useCallback, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Replaces the current page number from
 * the URL and returns the new string.
 * 
 * @param {int} page 
 * @param {object} location 
 */
const get_paginated_path = (page, location, query) => {
  const url = new URL(window.location.href);
  url.searchParams.set('page', page);
  if (query) {
    url.searchParams.set('query', query);
  } else {
    url.searchParams.delete('query');
  }
  return `${url.pathname}${url.search}`;
}

const usePagination = (store, method = 'fetch', initial = 1, initialKeyword = '') => {
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const queryPage = isNaN(query.page) ? 1 : parseInt(query.page);
  const queryKeyword = query.search ? query.search : '';
  const [page, setPage] = useState(queryPage || initial);
  const [keyword, setKeyword] = useState(queryKeyword || initialKeyword)
  const fetcher = store[method];
  const loadingPages = fetcher.state === 'pending';

  useEffect(() => {
    fetcher(page, keyword);
  }, [fetcher, page, keyword]);

  useEffect(() => {
    return store.reset || undefined;
  }, [store.reset]);

  useEffect(() => {
    if (queryPage && queryPage !== page) {
      setPage(queryPage);
    }
  }, [queryPage, page, setPage]);

  return {
    ...store.pagination,
    loading: loadingPages,
    query: keyword,
    onChange: useCallback((p) => {
      setPage(p);
      history.push(get_paginated_path(p, location));
    }, [history, location]),
    onSearch: useCallback((k) => {
      setKeyword(k);
      setPage(1);
      history.push(get_paginated_path(1, location, k));
    }, [history, location]),
  };
};

export default usePagination;
