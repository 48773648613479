/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, ListSubheader } from '@material-ui/core';
import { PATHS } from 'config';
import { useBoolean } from 'hooks';
import { ConfirmLogOut } from 'components';
import { user_can_see } from 'helpers';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  subheader: {
    padding: '0px 8px'
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    lineHeight: '19px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { menu, className, closeDrawer, ...rest } = props;
  const classes = useStyles();
  const loggingOut = useBoolean(false);

  const onButtonClick = e => {
    const { href } = e.currentTarget;

    if (href.indexOf(PATHS.logout) >= 0) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      loggingOut.setTrue();
    } else {
      closeDrawer();
    }
  }

  return (
    <React.Fragment>
      {loggingOut && (
        <ConfirmLogOut showing={loggingOut} />
      )}
      {menu.map((item, i) => {
        const { pages, header, permissions } = item

        if (permissions && !user_can_see(permissions)) {
          return null;
        }
        
        const hasPages = pages.some(p => p.permissions ? user_can_see(p.permissions, false) : true)
        
        if (!hasPages) {
          return null;
        }
        
        return (
          <List
            {...rest}
            className={clsx(classes.root, className)}
            key={`menu-item-${i}`}
            subheader={!header ? null : ( 
              <ListSubheader
                className={classes.subheader}
                component="div"
              >
                {header}
              </ListSubheader>
            )}
          >
            {pages.map(page => {
              const buttonProps = {};

              if (page.permissions && !user_can_see(page.permissions, false)) {
                return null;
              }
              
              if (page.exact !== true) {
                buttonProps.exact = false;
              }

              return (
                <ListItem
                  className={classes.item}
                  disableGutters
                  key={page.title}
                >
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    onClick={onButtonClick}
                    to={page.href}
                    {...buttonProps}
                  >
                    <div className={classes.icon}>{page.icon}</div>
                    {page.title}
                  </Button>
                </ListItem>
              )
            })}
          </List>
        );
      })}
    </React.Fragment>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  menu: PropTypes.array.isRequired
};

export default observer(SidebarNav);
