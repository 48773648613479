import React from 'react';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from '../../styles';
const FormActions = ({ loading, onCancel, labelSubmit }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {onCancel && (
        <Button
          className={classes.actionCancel}
          color="default"
          disabled={loading.value}
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
      
      <div className={classes.actionWrapper}>
        <Button
          color="primary"
          disabled={loading && loading.value}
          type="submit"
          variant="contained"
        >
          {labelSubmit || 'Submit'}
        </Button>

        {loading && loading.value && (
          <CircularProgress
            className={classes.actionProgress}
            size={24}
          />
        )}
      </div>
    </React.Fragment>
  )
}

FormActions.propTypes = {
  labelSubmit: PropTypes.string,
  loading: PropTypes.object,
  onCancel: PropTypes.func,
}

export default FormActions;
