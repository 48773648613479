import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

const BasicSwitch = props => {
  const { label, ...rest } = props;

  return (
    <FormControlLabel
      control={(
        <Switch {...rest} />
      )}
      label={label}
    />
  );
};

BasicSwitch.defaultProps = {
  color: 'primary',
};

BasicSwitch.propTypes = {
  color: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BasicSwitch;

