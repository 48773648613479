import Client from './client';

export default {
  profile: () => Client.get('/profile'),
  forgotPassword: email => Client.post('/auth/forgot-password?', { email }),
  login: (email, password) => Client.post('/auth/login', { email, password }),
  logout: () => Client.post('/auth/logout'),
  resetPassword: (email, token, password, passwordConfirmation) => Client.post('/auth/reset-password', {
    email,
    token,
    password,
    password_confirmation: passwordConfirmation
  }),
}
