import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const FormLayout = ({ left, right }) => {
  const leftProps = {
    lg: right ? 8 : 12,
    md: right ? 8 : 12,
    xl: right ? 8 : 12,
    xs: 12,
  };

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        {...leftProps}
      >
        {left}
      </Grid>
      {right && (
        <Grid
          item
          lg={4}
          md={4}
          xl={4}
          xs={12}
        >
          {right}
        </Grid>
      )}
    </Grid>
  );
};

FormLayout.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

export default FormLayout;
