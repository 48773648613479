import React, { useContext } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SlimProgress } from 'components';
import { observer } from 'mobx-react-lite';
import { AuthStore } from 'stores';
import { PATHS } from 'config';

export const AUTH_TYPE_GUEST = 'guest';
export const AUTH_TYPE_USER = 'user';
export const AUTH_TYPE_ALL = 'all';

const RouteWithLayout = props => {
  const authStore = useContext(AuthStore);
  const {
    layout: Layout,
    component: Component,
    authType,
    location,
    ...rest
  } = props;

  if (authType === AUTH_TYPE_USER && !authStore.user) {
    const back = encodeURIComponent(`${location.pathname}${location.search}`)
    return <Redirect to={`${PATHS.login}?back_to=${back}`} />;
  } else if (authType === AUTH_TYPE_GUEST && authStore.user) {
    return <Redirect to={PATHS.dashboard} />
  }

  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <Layout>
            <React.Suspense fallback={<SlimProgress />}>
              <Component {...matchProps} />
            </React.Suspense>
          </Layout>
        )
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  authType: PropTypes.oneOf([AUTH_TYPE_GUEST, AUTH_TYPE_USER, AUTH_TYPE_ALL]),
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default withRouter(observer(RouteWithLayout));
