import uuid from 'uuid/v4';

export const droppable_reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const droppable_copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];
  
  destClone.splice(droppableDestination.index, 0, {
    ...item,
    id: uuid(),
    module_id: item.id,
  });

  return destClone;
};
