import axios from 'axios';
import { API_URL } from 'config';

const instance = axios.create({
  baseURL: `${API_URL}/`,
  responseType: 'json'
});

instance.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('jwt');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
