import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from '@material-ui/core';

export const COLUMN_TYPES = {
  half: 6,
  third: 4,
  full: 12,
  fourth: 3,
};

const FormColumn = props => {
  const {
    children,
    size,
  } = props;

  const column = {
    item: true,
    md: COLUMN_TYPES[size] ? COLUMN_TYPES[size] : size,
    xs: 12,
  };

  return (
    <Grid {...column}>
      {children}
    </Grid>
  );
}

FormColumn.defaultProps = {
  size: COLUMN_TYPES.half,
};

FormColumn.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(Object.keys(COLUMN_TYPES))
  ])
};

FormColumn.COLUMN_TYPES = COLUMN_TYPES;

export default FormColumn;
