import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  warningButton: {
    color: '#ff9800',
    borderColor: '#ff9800',
  },
  dangerButton: {
    color: '#f44336',
    borderColor: '#f44336',
  },
  positiveButton: {
    color: '#4caf50',
    borderColor: '#4caf50',
  },
  actionButton: {
    marginLeft: theme.spacing(2),
    fontSize: '11px',
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ActionsRow = ({ actions, item }) => {
  const classes = useStyles();

  return (
    <div>
      {actions.map((action, i) => {
        const buttonProps = {
          'aria-label': action.label,
          onClick: () => action.onClick(item || null),
          size: 'small',
        };

        let buttonBackground = {};
        let buttonColor = action.buttonColor || 'default';

        if (action.buttonColor === 'danger') {
          buttonBackground = classes.dangerButton;
          buttonColor = 'default';
        } else if (action.buttonColor === 'warning') {
          buttonBackground = classes.warningButton;
          buttonColor = 'default';
        } else if (action.buttonColor === 'positive') {
          buttonBackground = classes.positiveButton;
          buttonColor = 'default';
        }

        return action.icon ? (
          <Tooltip
            key={`${i}-${action.label}`}
            title={action.tooltip || action.label}
          >
            <IconButton {...buttonProps}>
              {{...action.icon, fontSize: 'inherit'}}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className={clsx(classes.actionButton, buttonBackground)}
            color={buttonColor}
            key={`${i}-${action.label}`}
            variant="outlined"
            {...buttonProps}
          >
            {action.label}
          </Button>
        )
      })}
    </div>
  )
}

ActionsRow.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  })),
  item: PropTypes.any,
}

export default ActionsRow;
