import { useContext } from 'react';
import { AuthStore } from 'stores';

const useCurrentUser = () => {
  const store = useContext(AuthStore);

  return store.user;
};

export default useCurrentUser;
