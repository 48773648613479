import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';

const FormSwitch = props => {
  const {
    defaultValue,
    label,
    on,
    off,
    input,
  } = props;

  const [currentLabel, setCurrentLabel] = useState(defaultValue ? on : off)

  const customInput = {
    ...input,
    color: 'primary',
  };

  delete customInput.error
  delete customInput.fullWidth
  delete customInput.margin
  delete customInput.helperText

  return (
    <FormControl
      component="div"
      error={input.error}
    >
      <FormLabel component="span">{label}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              {...customInput}
              defaultChecked={defaultValue}
              onChange={() => setCurrentLabel(currentLabel === off ? on : off)}
            />
          )}
          label={currentLabel}
        />
      </FormGroup>
    </FormControl>
  );
}

FormSwitch.defaultProps = {
  defaultValue: false,
  off: 'Off',
  on: 'On',
};

FormSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  off: PropTypes.string,
  on: PropTypes.string,
};

export default FormSwitch;
