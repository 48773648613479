import Client from './client';

export default {
  get: (campaign, locales, page) => Client.get('/comments', {
    params: {
      campaign_id: campaign,
      locale: locales,
      page,
    }
  }),
  store: (campaign, locales, comment) => Client.post('/comments', {
    campaign_id: campaign,
    locale: locales,
    comment,
  }),
}
