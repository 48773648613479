import Client from './client';

export default {
  get: (page = 1, query) => Client.get(`/campaigns?page=${page}${query ? `&query=${query}` : ''}`),
  find: (id) => Client.get(`/campaigns/${id}`),
  destroy: id => Client.delete(`/campaigns/${id}`),
  approve: (id, locales) => Client.post('/approvals', {
    campaign_id: id,
    locales,
    status: 1
  }),
  assets: (id, page) => Client.get(`/assets?campaign_id=${id}&page=${page}`),
  store: data => Client.post('/campaigns', data),
  duplicate: data => Client.post(`/campaigns/${data.id}/duplicate`, data),
  update: data => Client.put(`/campaigns/${data.id}`, data),
  updateStepStatus: (campaign_id, step, status, extra) => Client.post('/campaignstatuses', { campaign_id, step, status, ...extra }),
  categories: () => Client.get('/campaigns/categories'),
  uploadAsset: (id, asset) => {
    const data = new FormData() 
    data.append('file', asset)
    return Client.post(`/assets?campaign_id=${id}`, data)
  },
  destroyAsset: id => Client.delete(`/assets/${id}`, { }),
}
