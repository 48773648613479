import { createContext } from 'react';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { UserAgent } from 'agents';

export class User {
  users = [];
  pagination = null;

  /**
   * Fetch all users from API.
   */
  all = task(async () => {
    return await UserAgent.all().then(r => r.data);
  });

  /**
   * Find a specific user by the given ID.
   */
  find = task(async (id) => {
    return await UserAgent.find(id).then(r => r.data);
  });

  /**
   * Fetch paginated users from API.
   */
  fetch = task(async (page) => {
    const response = await UserAgent.fetch(page);
    
    runInAction(() => {
      this.users = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  /**
   * Create a new user
   */
  store = async (attrs) => {
    const response = await UserAgent.store(attrs);
    const data = response.data.data;

    runInAction(() => {
      this.users.unshift(data);
    });

    return data;
  };

  /**
   * Update a user
   */
  update = async attrs => {
    const response = await UserAgent.update(attrs).then(r => r.data);
    const { data } = response;

    return data;
  };

  /**
   * Delete the given user from the DB
   */
  destroy = async (id) => {
    await UserAgent.destroy(id);
  };

  /**
   * Activate/Deactivate the given user
   */
  toggleActive = async (user, status) => {
    await UserAgent.toggleActive(user, status);
    const found = this.users.find(u => parseInt(user.id) === u.id);
    if (found) {
      user.active = !!status;
    }
  };

  /**
   * Reset the current set of items
   */
  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.users = [];
  }
}

decorate(User, {
  users: observable
});

export default createContext(new User());
