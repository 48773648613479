import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RepeaterBox, Repeater, Add, Connector, Remove } from './styles';
import { TextField, IconButton } from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@material-ui/icons';

const FormRepeater = props => {
  const {
    defaultValue,
    disabled,
    error,
    input,
    required,
    fieldName,
    label,
  } = props;

  const [values, setValues] = useState(defaultValue.length ? defaultValue : ['']);

  const updateValue = (e, key) => {
    const v = [...values];
    v[key] = e.target.value;
    setValues(v);
  }

  const addNewRepeater = () => setValues([...values, '']);
  const removeRepeater = i => {
    const v = [...values];
    v.splice(i, 1);
    setValues(v);
  }

  const count = values.length;
  
  return (
    <RepeaterBox>      
      {values.map((value, i) => {
        const key = i + 1;
        const name = `${fieldName}[${i}]`;
        const details = input(`${name}`, { required });

        return (
          <Repeater key={i}>
            {i > 0 && (
              <Connector />
            )}

            <TextField
              defaultValue={value}
              disabled={disabled}
              error={error}
              label={i === 0 ? label : null}
              onBlur={() => key < count && !value.length ? removeRepeater(i) : null}
              onChange={e => updateValue(e, i)}
              {...details}
            />
            {count > key && (
              <Remove>
                <IconButton
                  onClick={() => removeRepeater(i)}
                  size="small"
                >
                  <RemoveOutlined fontSize="inherit" />
                </IconButton>
              </Remove>
            )}
            {!!value.length && count === key && (
              <Add>
                <IconButton
                  onClick={addNewRepeater}
                  size="small"
                >
                  <AddOutlined fontSize="inherit" />
                </IconButton>
              </Add>
            )}
          </Repeater>
        )
      })}
    </RepeaterBox>
  );
};

FormRepeater.propTypes = {
  defaultValue: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  fieldName: PropTypes.string,
  input: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

export default FormRepeater;
