import React from 'react';
import {
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ActionsRow } from 'components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';

const PaperHeader = props => {
  const {
    actions,
    backArrowIcon,
    className,
    fullWidth,
    onBackArrowClick,
    title,
    headerActions,
  } = props
  const classes = useStyles();
  const HeaderBackIcon = backArrowIcon ? backArrowIcon : ArrowBackIcon

  return (
    <div
      className={clsx({
        [classes.headerBox]: true,
        [classes.headerFullWidth]: fullWidth
      }, className)}
    >
      <Paper className={classes.header}>
        {onBackArrowClick && (
          <IconButton
            className={classes.headerBackArrow}
            onClick={onBackArrowClick}
          >
            <HeaderBackIcon />
          </IconButton>
        )}

        <Typography
          className={clsx(onBackArrowClick ? classes.titlePaddingLeft : {})}
          variant="h6"
        >
          {title}
        </Typography>
        
        {headerActions && (
          <div className={classes.headerActions}>
            {headerActions}
          </div>
        )}

        {actions && (
          <div className={classes.headerActions}>
            <ActionsRow
              actions={actions}
            />
          </div>
        )}
      </Paper>
    </div>
  )
}

PaperHeader.defaultProps = {
  fullWidth: false,
}

PaperHeader.propTypes = {
  actions: PropTypes.array,
  backArrowIcon: PropTypes.object,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  headerActions: PropTypes.node,
  onBackArrowClick: PropTypes.func,
  title: PropTypes.string.isRequired,
}

export default PaperHeader;
