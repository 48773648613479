import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionCancel: {
    marginRight: theme.spacing(2)
  },
  actionWrapper: {
    position: 'relative',
  },
  actionProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
