import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Slide,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const StyledDialog = withStyles({
  root: {
    width: props => `${props.width}%`,
    marginLeft: 'auto',
  },
  paper: {
    backgroundColor: '#f4f6f8',
  }
})(Dialog);

// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
    />
  );
});

// eslint-disable-next-line react/no-multi-comp
const Stack = ({ children, needsPadding, width }) => {
  const classes = useStyles();

  return (
    <StyledDialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
      open
      // eslint-disable-next-line react/jsx-sort-props
      TransitionComponent={Transition}
      width={width}
    >
      <div className={needsPadding ? classes.root : null}>
        {children}
      </div>
    </StyledDialog>
  );
};

Stack.defaultProps = {
  needsPadding: true,
  width: 80,
};

Stack.propTypes = {
  children: PropTypes.node,
  needsPadding: PropTypes.bool,
  width: PropTypes.number,
};

export default observer(Stack);
