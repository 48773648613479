import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { PATHS } from 'config';
import { useBoolean } from 'hooks';
import { ConfirmLogOut } from 'components';
import { AuthStore } from 'stores';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  username: {
    fontSize: '12px',
    fontWeight: 700,
    marginRight: theme.spacing(2)
  },
  logoImage: {
    width: '150px',
    position: 'relative',
    left: '-20px',
  },
  iconMenu: {
    marginRight: '10px',
    marginLeft: '-16px',
  },
}));

const ColorLinearProgress = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'none',
  },
  colorPrimary: {
    backgroundColor: '#999999',
  },
  barColorPrimary: {
    backgroundColor: '#363636',
  },
})(LinearProgress);

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const auth = useContext(AuthStore);
  const classes = useStyles();
  const loggingOut = useBoolean(false);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      {loggingOut.value && (
        <ConfirmLogOut showing={loggingOut} />
      )}

      <Toolbar>
        <ColorLinearProgress className={classes.loader} />
        {onSidebarOpen && (
          <Hidden lgUp>
            <IconButton
              className={classes.iconMenu}
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        <RouterLink to={PATHS.dashboard}>
          <img
            alt="Logo"
            className={classes.logoImage}
            src="/images/logos/IVALDI_logo_360.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={loggingOut.setTrue}
          >
            <div className={classes.username}>{auth.user.first_name} {auth.user.last_name}</div>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
