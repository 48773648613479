/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  base: {
    color: 'rgb(102, 60, 0)',
    display: 'flex',
    padding: '12px 16px',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    borderRadius: '4px',
    letterSpacing: '0.01071em',
    marginBottom: '24px' 
  },
  info: {
    backgroundColor: '#e6f7ff',
    border: '2px dashed #91d5ff',
  },
  warning: {
    backgroundColor: 'rgb(255, 244, 229)',
    border: '2px dashed rgb(102, 60, 0.3)',
  },
});


const Alert = props => {
  const classes = useStyles();
  const {
    children,
    type
  } = props;

  return (
    <div className={clsx(classes.base, classes[type])}>
      {children}
    </div>
  );
}

Alert.defaultProps = {
  type: 'info'
};

Alert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['info', 'warning']),
};

export default Alert;
