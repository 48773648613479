import React, { useState, useEffect } from 'react';
import Frame from 'react-frame-component';
import PropTypes from 'prop-types';

const HeightChangingFrame = ({ children }) => {
  const [height, setHeight] = useState(500);
  const iframeRef = React.createRef();
  const handleResize = (iframe) => {
    if (
      iframe.current &&
      iframe.current.node.contentDocument &&
      iframe.current.node.contentDocument.body.scrollHeight !== 0
    ) {
      setHeight(iframe.current.node.contentDocument.body.scrollHeight);
    }
  };
  useEffect(() => handleResize(iframeRef), [children, iframeRef]);
  useEffect(() => {
    setTimeout(() => handleResize(iframeRef), 300)
  }, [iframeRef])

  return (
    <Frame
      onLoad={() => handleResize(iframeRef)}
      ref={iframeRef}
      style={{
        width: '100%',
        border: 0,
        height,
      }}
    >
      {children}
    </Frame>
  );
};

HeightChangingFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeightChangingFrame;
