import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
}));

const TopBarOnly = props => {
  const { children } = props;

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
};

TopBarOnly.propTypes = {
  children: PropTypes.node
};

export default TopBarOnly;
