/**
 * Filters the given array by searching for keywords
 * inside of the searchable fields.
 * 
 * @param string items 
 * @param string query 
 * @param array searchable 
 */
export const arr_inline_search = (items, query, searchable) => {
  if (!query) {
    return items;
  }

  return items.filter(item => {
    let filtered = false;

    searchable.forEach(field => {
      if (!filtered && item[field].toLowerCase().includes(query.toLowerCase())) {
        filtered = true;
      }
    });

    return filtered;
  });
};
