import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Confirmation } from 'components';
import { PATHS } from 'config';

const ConfirmLogOut = ({ showing }) => {
  const history = useHistory();
  return (
    <Confirmation
      description="Are you sure you want to logout from your accout?"
      labelAgree="Yes, Log out"
      onCancel={showing.setFalse}
      onConfirm={() => history.push(PATHS.logout)}
      open={showing.value}
      title="You are about to logout"
    />
  );
};

ConfirmLogOut.propTypes = {
  showing: PropTypes.object.isRequired
}

export default ConfirmLogOut;
