import { createContext } from 'react';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { RoleAgent } from 'agents';

export class Role {
  roles = [];
  pagination = null;

  /**
   * Fetch all roles from API.
   */
  all = task(async () => {
    return await RoleAgent.all().then(r => r.data);
  });

  /**
   * Fetch paginated roles from API.
   */
  fetch = task(async (page) => {
    const response = await RoleAgent.fetch(page);

    runInAction(() => {
      this.roles = response.data.data;
      this.pagination = { ...response.data, data: null };
    });

    return response;
  });

  /**
   * Find a specific role by the given ID.
   */
  find = task(async (id) => {
    return await RoleAgent.find(id).then(r => r.data);
  });

  /**
   * Create a new role
   */
  store = async (attrs) => {
    const response = await RoleAgent.store(attrs);
    const data = response.data.data;

    runInAction(() => {
      this.roles.unshift(data);
    });

    return data;
  };

  /**
   * Update a role
   */
  update = async attrs => {
    const response = await RoleAgent.update(attrs).then(r => r.data);
    const { data } = response;

    return data;
  };

  /**
   * Delete the given role from the DB
   */
  destroy = async (id) => {
    await RoleAgent.destroy(id);
    this.roles.remove(this.roles.find(s => parseInt(id) === s.id));
  };

  /**
   * Reset the current set of items
   */
  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.roles = [];
  }
}

decorate(Role, {
  roles: observable
});

export default createContext(new Role());
