import { createContext } from 'react';
import { decorate, observable, action, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { CommentAgent } from 'agents';

export class Comment {
  /** @var {array} */
  items = [];

  /** @var {object} */
  pagination = null;

  /**
   * Fetch comments for the given campaign.
   * 
   * @param {int} campaignId
   * @param {array} locales
   */
  fetch = task(async (campaignId, locales, page = 1) => {
    const response = await CommentAgent.get(campaignId, locales, page).then(r => r.data);
    const { data, ...pagination } = response;

    runInAction(() => {
      data.reverse();
      this.items = data.concat(this.items);
      this.pagination = pagination;
    });

    return response;
  });

  /**
   * Clear the current set of items.
   */
  reset = () => {
    this.items = [];
  };

  /**
   * Submit and store a new comment.
   * 
   * @param {int} campaignId
   * @param {array} locales
   * @param {string} message
   */
  store = async (campaignId, locales, message) => {
    const response = await CommentAgent.store(campaignId, locales, message).then(r => r.data);
    this.items.push(response.data);
  };
}

decorate(Comment, {
  reset: action,
  items: observable,
  pagination: observable,
  store: action,
});

export default createContext(new Comment());
