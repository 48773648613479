import Client from './client';

export default {
  get: (campaign, locale) => Client.get(`/data?campaign_id=${campaign}&locale=${locale}`),
  update: (params) => Client.post('/data/updatelist', params),
  upload: (id, file) => {
    const data = new FormData(); 
    data.append('file', file);
    return Client.post(`/datafiles?campaign_id=${id}`, data);
  },
}
