import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, IconButton, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  loading: {
    position: 'absolute',
    top: 12,
    right: 45,
  },
  loadingFirst: {
    position: 'absolute',
    top: 12,
    right: 10,
  },
  clearButton: {
    position: 'absolute',
    top: -1,
    right: 0,
  }
}));

const SearchInput = props => {
  const { className, clearButtonClass, clickToSearch, onSearch, placeholder, style, loading, ...rest } = props;
  const [query, setQuery] = React.useState('');
  const classes = useStyles();

  const onResetClick = () => {
    setQuery('')
    onSearch('')
  }

  const onKeyDown = (e) => {
    if (!clickToSearch) {
      return
    }

    if (e.key === 'Enter') {
      onSearch(query)
    }
  }

  const onChange = (e) => {
    const { value } = e.currentTarget;
    setQuery(value);
    
    if (clickToSearch) {
      return
    }

    onSearch && onSearch(value);
  }

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      {clickToSearch ? null : (
        <SearchIcon className={classes.icon} />
      )}
      
      <Input
        className={classes.input}
        disableUnderline
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={query}
      />
      {loading && (
        <CircularProgress
          className={query ? classes.loading : classes.loadingFirst}
          size={20}
        />
      )}
      {query && (
        <IconButton
          className={clsx(classes.clearButton, clearButtonClass)}
          onClick={onResetClick}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  clearButtonClass: PropTypes.string,
  clickToSearch: PropTypes.bool,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

export default SearchInput;
