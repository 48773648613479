import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    textAlign: 'center',
    maxWidth: '590px',
    margin: '0 auto',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 240
  },
  subtitle: {
    marginTop: theme.spacing(2)
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h4">
              You don't have access to this area.
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
            >
              Try to navigate to the home page or, if this URL was given to you, contact the person so they know about the issue.
            </Typography>
            <img
              alt="Not allowed"
              className={classes.image}
              src="/images/undraw_access_denied_6w73.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
