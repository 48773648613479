import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: '13px',
    borderRadius: '100%',
    fontWeight: 700,
    margin: '0 4px',
    padding: 0,
    minWidth: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  linkCurrent: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    // backgroundColor: theme.palette.secondary.dark,
  },
  loader: {
    marginRight: theme.spacing(1),
  },
  links: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));
