import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  Button,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  withMarginTop: {
    marginTop: theme.spacing(2),
  },  
  withMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  withOverflow: {
    overflow: 'auto',
  },
  normalPaddingBottom: {
    paddingBottom: '16px!important',
  },
  actionButton: {
    marginTop: '8px',
  },
  actionIcon: {
    marginLeft: theme.spacing(1),
  },
  smallActionIcon: {
    fontSize: '18px',
  },
  actionSlot: {
    display: 'flex',
  }
}));

const StyledCardHeader = withStyles({
  root: {
    position: 'relative',
  },
  action: {
    position: 'absolute',
    right: '12px',
    top: '12px',
  },
})(CardHeader);

const FormBox = props => {
  const {
    action,
    actionSlot,
    children,
    title,
    marginTop,
    marginBottom,
    withOverflow,
    needsPadding,
  } = props;
  const classes = useStyles();

  return (
    <Card
      className={clsx({
        [classes.withMarginTop]: marginTop,
        [classes.withMarginBottom]: marginBottom,
        [classes.withOverflow]: withOverflow,
      })}
    >
      {title && (
        <React.Fragment>
          <StyledCardHeader
            action={action ? (
              action.label ? (
                <Button
                  className={classes.actionButton}
                  onClick={action.onClick}
                  size="small"
                >
                  {action.label}
                  {{
                    ...action.icon,
                    props: {
                      className: clsx({
                        [classes.actionIcon]: true,
                        [classes.smallActionIcon]: action.smallActionIcon
                      })
                    }
                  }}
                </Button>
              ) : (
                <IconButton onClick={action.onClick}>
                  {action.icon}
                </IconButton>
              )
            ) : actionSlot ? (<div className={classes.actionSlot}>{actionSlot}</div>) : null}
            subheader={title}
          />
          <Divider />
        </React.Fragment>
      )}
      {needsPadding ? (
        <CardContent className={clsx(!title ? classes.normalPaddingBottom : {})}>
          {children}
        </CardContent>
      ) : children}
    </Card>
  );
};

FormBox.defaultProps = {
  needsPadding: true,
  marginBottom: false,
};

FormBox.propTypes = {
  action: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    label: PropTypes.string,
    smallActionIcon: PropTypes.bool,
  }),
  actionSlot: PropTypes.node,
  children: PropTypes.node,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  needsPadding: PropTypes.bool,
  title: PropTypes.string,
  withOverflow: PropTypes.bool,
};

export default FormBox;
