export default [
  {
    message: 'Wherever, Whenever, Forever',
    from: 'PS1'
  },
  {
    message: 'Live In Your World, Play In Ours',
    from: 'PS2'
  },
  {
    message: 'It Only Does Everything',
    from: 'PS3'
  },
  {
    message: 'Long Live Play',
    from: 'PS3'
  },
  {
    message: 'Greatness Awaits',
    from: 'PS3/PS4'
  },
  {
    message: 'This is 4 the Players',
    from: 'PS4',
  },
  {
    message: 'War has changed',
    from: 'Solid Snake',
  },
  {
    message: 'Hope is what makes us strong. It is why we are here. It is what we fight with when all else is lost.',
    from: 'Pandora',
  }
];
