import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    textAlign: 'center',
    maxWidth: '590px',
    margin: '0 auto',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 340
  },
  subtitle: {
    marginTop: theme.spacing(1)
  }
}));

const EmptyContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h5">
              The content you are looking for isn’t here.
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
            >
              We couldn't find any items.
            </Typography>
            <img
              alt="No content"
              className={classes.image}
              src="/images/undraw_empty_xct9.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyContent;
