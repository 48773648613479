import { createContext } from 'react';
import { decorate, observable, runInAction, action } from 'mobx';
import { CampaignAgent } from 'agents';
import { task } from 'mobx-task';
import uuid from 'uuid/v4';
import { IMAGE_MAX_MB_SIZE } from 'config/image';

export class Asset {
  /** @var array list of available images */
  items = [];

  /** @var array list of processing images */
  uploading = [];

  /**
   * Return and append images.
   * 
   * @param int id 
   * @param int page
   */
  fetch = task(async (id, page) => {
    const response = await CampaignAgent.assets(id, page).then(r => r.data);
    const { data } = response;
    
    if (data.length) {
      runInAction(() => {
        this.items = this.items.concat(data);
      });
    }

    return data;
  });

  /**
   * @param object image
   */
  removeFromUploading = (image) => {
    const found = this.uploading.find(i => i.key === image.key);
    this.uploading.remove(found);
  };

  /**
   * @param object image
   */
  reprocess = (image) => {
    image.loading = true;
    image.status = 0;

    this.store(image);
  };

  /**
   * @param int campaignId
   * @param array files
   */
  process = (campaignId, files, campaign) => {
    files.map(file => {
      let valid = (file.size / 1048576) <= IMAGE_MAX_MB_SIZE;
      const key = uuid();

      this.uploading.unshift({
        name: file.path,
        size: file.size,
        loading: true,
        campaignId,
        campaign,
        key,
        file,
        valid,
        status: 0,
        preview: URL.createObjectURL(file)
      });

      const image = this.uploading.find(i => i.key === key);
      
      if (valid) {
        this.store(image);
      } else {
        image.status = 422;
        image.loading = false;
      }

      return file;
    });
  }

  /**
   * @param int id
   * @param object asset
   * @param string key
   */
  store = task(async (image) => {
    const { campaignId, file, campaign } = image;
    let response;

    try {
      response = await CampaignAgent.uploadAsset(campaignId, file);
      const data = response.data.data;
      runInAction(() => {
        this.items.unshift(data);
        if (campaign) {
          campaign.assets.unshift(data);
          campaign.assets = campaign.assets.slice(0, 4);
          campaign.count_assets = campaign.count_assets + 1;
        }
      });
    } catch (e) {
      response = e.response;
    }

    image.loading = false;

    if (response) {
      image.status = response.status;
    } else {
      image.status = 500;
    }

    return response;
  });

  destroy = (image) => {
    const found = this.items.find(i => parseInt(image.id) === i.id);
    found.status = 'DELETING';
    CampaignAgent.destroyAsset(image.id);
  };
}

decorate(Asset, {
  items: observable,
  uploading: observable,
  process: action,
  reprocess: action,
  remove: action,
  destroy: action,
});

export default createContext(new Asset());
