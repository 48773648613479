import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Error404 from 'views/Errors/404';
import Error401 from 'views/Errors/401';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
}));

const StatusError = ({ fetchers, error }) => {
  const classes = useStyles();

  if (error) {
    fetchers.push({ error });
  }
  
  try {
    let notFound = fetchers.filter(fetcher => fetcher.error && fetcher.error.response && fetcher.error.response.status === 404)
    if (notFound.length) {
      return <Error404 />
    }
  // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    let notAuthorised = fetchers.filter(fetcher => fetcher.error && fetcher.error.response && fetcher.error.response.status === 401)
    if (notAuthorised.length) {
      return <Error401 />
    }
  // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    let notAuthorised = fetchers.filter(fetcher => fetcher.error && fetcher.error.response && fetcher.error.response.status === 403)
    if (notAuthorised.length) {
      return <Error401 />
    }
  // eslint-disable-next-line no-empty
  } catch (e) {}

  return (
    <div className={classes.root}>
      <Typography variant="h6">Something went wrong</Typography>
      <Typography
        gutterBottom
        variant="body1"
      >
        Please, try again later. If the problem persists, get in touch with the development team.
      </Typography>

      {fetchers.map(fetcher => {
        if (!fetcher.error) {
          return null;
        }

        const { error: {
          response, message,
        } } = fetcher

        return (
          <React.Fragment key={message}>
            <br /><br /><Typography variant="caption">Error details</Typography><br />
      
            {response && (
              <React.Fragment>
                <Typography variant="caption"><strong>Status code: </strong></Typography>
                <code>{response.status}</code><br />
      
                <Typography variant="caption"><strong>URL: </strong></Typography>
                <code>{response.config.url}</code><br />
              </React.Fragment>
            )}
            <Typography variant="caption"><strong>Message: </strong></Typography>
            <code>{message}</code>
          </React.Fragment>
        )
      })}
    </div>
  );
};

StatusError.propTypes = {
  error: PropTypes.object,
  fetchers: PropTypes.array,
}

export default StatusError;
