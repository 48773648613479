import styled from 'styled-components';

export const Data = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
`;

export const CopyToolbar = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #efefef;
  margin-bottom: 16px;

  .MuiPaper-root {
    width: 100%;
    flex-basis: auto;
    height: 49px;
  }
`

export const HeaderRow = styled.div`
  display: flex;
  height: 40px;
  padding-right: 19px;
  background-color: #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  width: 40%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  &:first-child {
    width: 20%;
    border-left: 0;
  }
`

export const BodyRow = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: 0;
  }
`

export const EditInput = styled.input`
  display: block;
  width: calc(100% + 32px);
  height: calc(100% + 16px);
  padding: 8px 16px;
  margin: -8px -16px;
`

export const BodyCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  word-break: break-word;
  width: 40%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  &:first-child {
    width: 20%;
    border-left: 0;
  }
`

export const DataFooter = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  height: 50px;
  background-color: #efefef;
  border-top: 1px solid rgba(0,0,0,0.1);

  .MuiButton-containedPrimary {
    margin-left: 16px;
  }
`
