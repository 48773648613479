import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Form, FormColumn, FormGrid, FormSwitch } from 'components';
import { useForm } from 'hooks';
import { ATTR_FIELDS } from './config';

const EditInputFieldModal = ({ attribute: attr, errors, handleClose, title, formData, submitType }) => {
  const { input, loading, basicSubmit, onSubmit, setError } = useForm(formData)
  if (errors) {
    for (let [key, value] of Object.entries(errors)) {
      setError(key, 'laravel-error', value)
    }
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      open
    >
      <DialogTitle id="form-dialog-title">{title} - {attr.field_title}</DialogTitle>
      <DialogContent>
        <Form
          loading={loading}
          onCancel={handleClose}
          onSubmit={submitType === 'basic' ? basicSubmit : onSubmit}
        >
          <FormGrid>
            {ATTR_FIELDS.map((field, i) => {
              let props = {}
              if (field.multiline) {
                props.multiline = true;
                props.rows = 5;
                props.rowsMax = 10;
              }
              return (
                <FormColumn
                  key={`attr-field-${i}`}
                  size={FormColumn.COLUMN_TYPES.third}
                >
                  {field.type === 'text' && (
                    <TextField
                      {...input(field.key, { required: field.required })}
                      {...props}
                      defaultValue={attr[field.key]}
                      label={field.label}
                    />
                  )}

                  {field.type === 'switch' && (
                    <FormSwitch
                      defaultValue={!!(attr[field.key])}
                      input={input(field.key)}
                      label={field.label}
                    />
                  )}
                </FormColumn>
              )
            })}
          </FormGrid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

EditInputFieldModal.defaultProps = {
  title: 'Edit',
};

EditInputFieldModal.propTypes = {
  attribute: PropTypes.object.isRequired,
  errors: PropTypes.object,
  formData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitType: PropTypes.oneOf(['basic', 'full']).isRequired,
  title: PropTypes.string,
};

export default EditInputFieldModal;
