import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DataFooter, Data } from './styles';
import { DataToolbar } from './components';
import { HotTable } from '@handsontable/react';
import { Alert } from 'components';

const DataTable = props => {
  const {
    data,
    disabledLocales,
    editable,
    footer,
    locales,
    searchPlaceholder,
    showMaster,
    canEditMaster,
  } = props;
  
  const hot = useRef();
  const table = [];
  const headers = [];

  if (showMaster) {
    headers.push('Master');
  }

  const keys = data ? Object.keys(data) : [];
  const orderedLocales = keys.filter(value => -1 !== locales.indexOf(value));
  const localesInOrder = [...new Set([...orderedLocales, ...locales])];
  localesInOrder.forEach(locale => headers.push(locale));

  data.keys.forEach(key => {
    const row = [];
    if (showMaster) {
      row.push(data.ALL.contents[key] || '');
    }

    localesInOrder.forEach(locale => {
      // ToDo: create if ID doesn't exist
      if (!data[locale]) {
        data[locale] = {
          contents: {}
        }
      }

      row.push(data[locale].contents[key] || '');
    });

    table.push(row);
  });

  const onSearch = query => {
    const { hotInstance } = hot.current;
    hotInstance.getPlugin('search').query(query);
    hotInstance.render();
  }
  
  return (
    <React.Fragment>
      <DataToolbar
        onSearch={onSearch}
        placeholder={searchPlaceholder}
      />

      <Alert type="warning">
        Warning: Please do not remove html code from copy.
      </Alert>
      
    
      <Data>
        <HotTable
          colHeaders={headers}
          colWidths={250}
          contextMenu
          data={table}
          id="hot"
          ref={hot}
          rowHeaderWidth={200}
          rowHeaders={i => data.keys[i]}
          search
          settings={{
            autoRowSize: true,
            afterChange: function (changes) {
              if (!changes) {
                return;
              }

              changes.forEach(change => {
                const [row, col, , value] = change;
                const key = data.keys[row];
                const locale = headers[col];
                data[locale === 'Master' ? 'ALL' : locale].contents[key] = value;
              })
            },
            columns: (i) => ({
              readOnly: i === 0 ? !canEditMaster : (disabledLocales.includes(headers[i]) ? true : !editable)
            }),
            licenseKey: 'non-commercial-and-evaluation',
            readOnly: !editable,
            stretchH: 'all',
          }}
        />
      </Data>

      {footer ? (
        <DataFooter>
          {footer}
        </DataFooter>
      ) : null}
    </React.Fragment>
  );
};

DataTable.defaultProps = {
  canEditMaster: true,
  disabledLocales: [],
  editable: true,
  searchPlaceholder: 'Search copy content...',
  showMaster: true,
};

DataTable.propTypes = {
  canEditMaster: PropTypes.bool,
  data: PropTypes.object.isRequired,
  disabledLocales: PropTypes.array,
  editable: PropTypes.bool,
  footer: PropTypes.node,
  locales: PropTypes.array.isRequired,
  searchPlaceholder: PropTypes.string,
  showMaster: PropTypes.bool,
};

export default DataTable;
