import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PATHS } from 'config';
import { RouteWithLayout } from './components';
import { AUTH_TYPE_GUEST, AUTH_TYPE_USER, AUTH_TYPE_ALL } from './components/RouteWithLayout';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts';


const CampaignsIndex = React.lazy(() => import('./views/Campaigns'));
const CampaignsShow = React.lazy(() => import('./views/Campaigns/show'));
const CampaignEdit = React.lazy(() => import('./views/Campaigns/edit'));
const TerritoryApprovalIndex = React.lazy(() => import('./views/TerritoryApproval/index'));

const TemplateEdit = React.lazy(() => import('./views/Templates/edit'));
const TemplateGuestPreview = React.lazy(() => import('./views/Templates/guest-preview'));
const AuthLogin = React.lazy(() => import('./views/Auth/login'));
const AuthLogout = React.lazy(() => import('./views/Auth/logout'));
const AuthForgotPassword = React.lazy(() => import('./views/Auth/forgot-pasword'));
const AuthResetPassword = React.lazy(() => import('./views/Auth/reset-password'));
const ConfigurationsIndex = React.lazy(() => import('./views/Configurations'));
const ConfigurationEdit = React.lazy(() => import('./views/Configurations/edit'));
const ElementsIndex = React.lazy(() => import('./views/Elements'));
const ElementEdit = React.lazy(() => import('./views/Elements/edit'));
const Error404 = React.lazy(() => import('./views/Errors/404'));
const GlobalSettingsIndex = React.lazy(() => import('./views/GlobalSettings'));
const GlobalSettingEdit = React.lazy(() => import('./views/GlobalSettings/edit'));
const InputFieldsIndex = React.lazy(() => import('./views/InputFields'));
const InputFieldEdit = React.lazy(() => import('./views/InputFields/edit'));
const ModulesIndex = React.lazy(() => import('./views/Modules'));
const ModuleEdit = React.lazy(() => import('./views/Modules/edit'));
const RolesIndex = React.lazy(() => import('./views/Roles'));
const RolesEdit = React.lazy(() => import('./views/Roles/edit'));
const UsersIndex = React.lazy(() => import('./views/Users'));
const UsersEdit = React.lazy(() => import('./views/Users/edit'));

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={PATHS.dashboard}
        to={PATHS.campaigns}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_GUEST}
        component={AuthLogin}
        exact
        layout={MinimalLayout}
        path={PATHS.login}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_GUEST}
        component={AuthForgotPassword}
        exact
        layout={MinimalLayout}
        path={PATHS.forgotPassword}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_GUEST}
        component={AuthResetPassword}
        exact
        layout={MinimalLayout}
        path={PATHS.resetPassword}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={AuthLogout}
        exact
        layout={MinimalLayout}
        path={PATHS.logout}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={CampaignsIndex}
        exact
        layout={MainLayout}
        path={PATHS.campaigns}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={CampaignsShow}
        exact
        layout={MainLayout}
        path={PATHS.campaigns_show}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={CampaignEdit}
        exact
        layout={MainLayout}
        path={PATHS.campaigns_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ConfigurationsIndex}
        exact
        layout={MainLayout}
        path={PATHS.configurations}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ConfigurationEdit}
        exact
        layout={MainLayout}
        path={PATHS.configuration_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={TerritoryApprovalIndex}
        layout={MinimalLayout}
        path={PATHS.territory_approval}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={TemplateEdit}
        exact
        layout={MinimalLayout}
        path={PATHS.templates_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_ALL}
        component={TemplateGuestPreview}
        exact
        layout={MinimalLayout}
        path={PATHS.templates_preview}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ElementsIndex}
        exact
        layout={MainLayout}
        path={PATHS.elements}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ElementEdit}
        exact
        layout={MainLayout}
        path={PATHS.element_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={GlobalSettingsIndex}
        exact
        layout={MainLayout}
        path={PATHS.global_settings}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={GlobalSettingEdit}
        exact
        layout={MainLayout}
        path={PATHS.global_settings_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={InputFieldsIndex}
        exact
        layout={MainLayout}
        path={PATHS.input_fields}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={InputFieldEdit}
        exact
        layout={MainLayout}
        path={PATHS.input_field_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ModulesIndex}
        exact
        layout={MainLayout}
        path={PATHS.modules}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={ModuleEdit}
        exact
        layout={MainLayout}
        path={PATHS.module_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={RolesIndex}
        exact
        layout={MainLayout}
        path={PATHS.roles}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={RolesEdit}
        exact
        layout={MainLayout}
        path={PATHS.roles_edit}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={UsersIndex}
        exact
        layout={MainLayout}
        path={PATHS.users}
      />

      <RouteWithLayout
        authType={AUTH_TYPE_USER}
        component={UsersEdit}
        exact
        layout={MainLayout}
        path={PATHS.users_edit}
      />

      <RouteWithLayout
        component={Error404}
        layout={MinimalLayout}
      />
    </Switch>
  );
};

export default Routes;
