import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

export const RepeaterBox = styled.div`
`

export const Repeater = styled.div`
  position: relative;

  .MuiFormControl-fullWidth {
    margin: 0;
    position: relative;
    z-index: 20;
    background-color: #fff;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }
`

export const Connector = styled(props => <div {...props}><span /></div>)`
  position: absolute;
  z-index: 10;
  left: -8px;
  top: -19px;
  height: 32px;
  width: 25px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 5px;

  span {
    position: absolute;
    top: 0;
    right: -2px;
    width: 8px;
    height: 100%;
    background-color: #fff;
  }
`

export const Add = styled.div`
  position: absolute;
  z-index: 30;
  top: 7px;
  right: -12px;

  .MuiIconButton-sizeSmall {
    color: #FFFFFF;
    background-color: #0072CE;

    &:hover {
      background-color: #0061AF
    }
  }
`

export const Remove = styled.div`
  position: absolute;
  z-index: 30;
  top: 7px;
  right: -12px;

  .MuiIconButton-sizeSmall {
    color: #FFFFFF;
    background-color: ${theme.palette.error.main};

    &:hover {
      background-color: ${theme.palette.error.dark};
    }
  }
`
