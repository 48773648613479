import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { FormActions, FormAuto } from './components';
import { FormLayout, PaperHeader } from 'components';

const Form = props => {
  const { auto, header, onSubmit, showFooterActions } = props
  const classes = useStyles();
  const HeaderBackIcon = header && header.backArrowIcon ? header.backArrowIcon : ArrowBackIcon

  return (
    <form onSubmit={onSubmit}>
      {header && (
        <PaperHeader
          backArrowIcon={HeaderBackIcon}
          headerActions={header.showHeaderActions ? (
            <FormActions {...props} />
          ) : null}
          onBackArrowClick={header.onBackArrowClick}
          title={header.title}
        />
      )}

      {auto && (
        <FormAuto {...auto} />
      )}

      {props.children}

      {props.layout && (
        <FormLayout
          left={props.layout.left}
          right={props.layout.right}
        />
      )}

      {showFooterActions && (
        <div className={classes.actions}>
          <FormActions
            classes={classes}
            {...props}
          />
        </div>
      )}
    </form>
  )
}

Form.defaultProps = {
  showFooterActions: true,
}

Form.propTypes = {
  auto: PropTypes.shape(FormAuto.propTypes),
  children: PropTypes.node,
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    showHeaderActions: PropTypes.bool,
    onBackArrowClick: PropTypes.func,
    backArrowIcon: PropTypes.object,
  }),
  labelSubmit: PropTypes.string,
  layout: PropTypes.shape({
    left: PropTypes.node.isRequired,
    right: PropTypes.node,
  }),
  loading: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  showFooterActions: PropTypes.bool,
}

export default Form;
