import Client from './client';

export default {
  all: () => Client.get('/modules/all'),
  get: (page = 1) => Client.get(`/modules?page=${page}`),
  store: (data) => Client.post('/modules', data),
  find: id => Client.get(`/modules/${id}`),
  update: (data) => Client.put(`/modules/${data.id}`, data),
  destroy: (id) => Client.delete(`/modules/${id}`),
  uploadThumbnail: (id, thumbnail) => {
    const data = new FormData() 
    data.append('thumbnail', thumbnail)
    return Client.post(`/modules/${id}/uploadthumbnail`, data)
  },
  preview: (path) => Client.get(path, {
    responseType: 'text'
  }),
}
