/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/styles';
import { StatusLoading, StatusError } from './components';
import { useBoolean } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const render = (classes, needsPadding, children) => {
  return needsPadding ? (
    <div className={classes.root}>
      {children}
    </div>
  ) : children;
}

const StoreFetcher = (props) => {
  const { children, showLoader, fetcher, fetchers, centeredLoader, needsPadding } = props;
  const classes = useStyles();
  const [requests] = useState(fetcher ? [fetcher] : fetchers);
  const states = requests.map(f => f.state);
  const ready = useBoolean(false);
  const error = useBoolean(false);

  useEffect(() => {
    if (states.every(v => v === 'resolved')) {
      ready.setTrue();
    } else if (states.map(v => v === 'rejected').find(Boolean)) {
      ready.setTrue();
      error.setTrue();
    } else if (states.filter(v => v === 'pending').length) {
      ready.setFalse();
      error.setFalse();
    }
  }, [states, ready, error]);

  // some instances of `StoreFetcher` don't actually
  // need to hide the UI behind a generic loader
  // this is particulary useful for inline
  // pagination.
  if (!showLoader) {
    return render(classes, needsPadding, children);
  }

  if (!ready.value) {
    return <StatusLoading centered={centeredLoader} />;
  } else if (ready.value && error.value) {
    return <StatusError fetchers={requests.filter(x => x.rejected)} />;
  }

  return render(classes, needsPadding, children);
};

StoreFetcher.defaultProps = {
  centeredLoader: true,
  showLoader: true,
  needsPadding: true,
  fetchers: [],
};

StoreFetcher.propTypes = {
  centeredLoader: PropTypes.bool,
  children: PropTypes.node,
  fetcher: PropTypes.func,
  fetchers: PropTypes.array,
  needsPadding: PropTypes.bool,
  showLoader: PropTypes.bool,
};

export default observer(StoreFetcher);
