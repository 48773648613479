import { createContext } from 'react';
import { ConfigurationAgent } from 'agents';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';

export class Configuration {

  item = null;
  items = [];
  pagination = null;

  fetch = task(async (page = 1) => {
    const response = await ConfigurationAgent.get(page);
    runInAction(() => {
      this.items = response.data.data;
      this.pagination = { ...response.data, data: null };
    });
  });

  find = task(async (id = 1) => {
    const configurationResponse = await ConfigurationAgent.find(id);
    runInAction(() => {
      this.item = configurationResponse.data;
    });
  });

  updateConfiguration = task(async (data) => {
    await ConfigurationAgent.update(data)
  });

  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.items = [];
  }

}

decorate(Configuration, {
  item: observable,
  items: observable,
  pagination: observable
});

export default createContext(new Configuration());
