import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { AppAgent } from 'agents';

export class App {
  settings = {};

  boot = async () => {
    await this.fetchSettings();
  };

  /**
   * Fetch settings
   */
  fetchSettings = async () => {
    const response = await AppAgent.settings();
    this.settings = response.data;

    return response;
  };
}

decorate(App, {
  settings: observable
});

export default createContext(new App());
