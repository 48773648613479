/**
 * Create a path with parameters.
 * 
 * @param {string} path 
 * @param {object} params 
 */
export const route_create_path = (path, params) => {
  let pathParams = path;

  Object.keys(params).map(key => {
    pathParams = pathParams.replace(`:${key}`, params[key]);
    return key;
  });

  return pathParams;
}

/**
 * Push the user to a parametized route.
 * 
 * @param {object} history 
 * @param {string} path 
 * @param {object} params 
 * @param {string} method 
 */
export const route_go_to = (history, path, params, method = 'push') => {
  history[method](route_create_path(path, params));
}
