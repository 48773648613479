import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';

const FormSelect = props => {
  const {
    label,
    defaultValue,
    values,
    onChange,
    helperText,
    ...rest
  } = props;

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [value, setValue] = React.useState(defaultValue ? defaultValue : (values.length ? values[0].value : ''));
  const [selectValue, setSelectValue] = React.useState(value);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  
  return (
    <FormControl
      component="div"
      error={rest.error}
      fullWidth={rest.fullWidth}
      style={{width: '100%'}}
      variant={rest.variant}
    >
      <input
        name={rest.name}
        ref={rest.inputRef}
        type="hidden"
        value={value ? value.toString().trim() : value}
      />
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        disabled={rest.disabled}
        input={<OutlinedInput />}
        labelWidth={labelWidth}
        native
        onChange={e => {
          onChange && onChange(e);
          setValue(e.target.value);
          setSelectValue(e.target.value ? e.target.value : ' ');
        }}
        value={selectValue}
      >
        {rest.placeholder ? <option value="">{rest.placeholder}</option> : null}
        {values.map(v => (
          <option
            key={`select-option-${v.value}`}
            value={v.value}
          >{v.label}</option>
        ))}
      </Select>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

FormSelect.defaultProps = {
  required: false,
};

FormSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.array.isRequired,
};

export default FormSelect;
