import { authStore } from 'stores/auth';

/**
 * Test if the current user is allowed
 * to perform `group`, `my` and `all`
 * operations on the given campaign.
 * 
 * @param {string} permission
 * @param {object} campaign 
 * 
 * @return {boolean}
 */
export const user_campaign_can = (permission, campaign, group = 'campaigns') => {
  const user = authStore.user;

  if (!campaign) {
    return false;
  }

  if (user_can([`${permission}_all:${group}`])) {
    return true;
  } else if (user_can([`${permission}_group:${group}`]) && campaign.owner.company_name === user.company_name) {
    return true;
  } else if (user_can([`${permission}_my:${group}`]) && campaign.owner.id === user.id) {
    return true;
  }

  return false;
}

/**
 * Test the current user to make sure
 * it's allowed to perform any
 * of the given actions.
 * 
 * @param {array} permissions
 * @param {boolean} partial 
 * 
 * @return {boolean}
 */
export const user_can = (permissions, partial = true) => {
  const user = authStore.user;
  const regexList = permissions.map(permission => new RegExp(permission.replace('*', '_\\w+')));
  const matches = user.role.permissions.map(p => {
    return regexList.some(rx => rx.test(p));
  });

  return partial ? matches.some(m => m) : matches.filter(m => m === true).length === permissions.length;
};

/**
 * Test the current user to make sure he's available
 * to see at least one or all the pages provided
 * as parameter.
 * 
 * @param {array} pages 
 * @param {boolean} partial 
 * 
 * @return {boolean}
 */
export const user_can_see = (pages, partial = true) => {
  return user_can(pages.map(p => `browse*:${p}`), partial);
}
