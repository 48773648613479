export const ATTR_FIELDS = [
  {
    key: 'field_title',
    label: 'Title',
    type: 'text',
    required: true,
  },
  {
    key: 'field_title_alias',
    label: 'Title [alias]',
    type: 'text',
    required: true,
  },
  {
    key: 'field_name',
    label: 'Name',
    type: 'text',
    required: true,
  },
  {
    key: 'field_description',
    label: 'Description',
    type: 'text',
    multiline: true,
  },
  {
    key: 'field_description_alias',
    label: 'Description [alias]',
    type: 'text',
    multiline: true,
  },
  {
    key: 'field_style',
    label: 'Style',
    type: 'text',
    multiline: true,
  },
  {
    key: 'field_type',
    label: 'Field type',
    type: 'text',
    required: true,
  },
  {
    key: 'field_value',
    label: 'Value',
    type: 'text',
  },
  {
    key: 'field_defaultvalue',
    label: 'Default value',
    type: 'text',
  },
  {
    key: 'field_placeholder',
    label: 'Placeholder',
    type: 'text',
  },
  {
    key: 'field_placeholder_alias',
    label: 'Placeholder [alias]',
    type: 'text',
  },
  {
    key: 'field_required',
    label: 'Required',
    type: 'switch',
  },
  {
    key: 'field_validation',
    label: 'Validation',
    type: 'text',
  },
  {
    key: 'field_id',
    label: 'ID',
    type: 'text',
  },
  {
    key: 'field_class',
    label: 'Class name',
    type: 'text',
  },
  {
    key: 'field_rules',
    label: 'Rules',
    type: 'text',
    multiline: true,
  },
  {
    key: 'field_settings',
    label: 'Settings',
    type: 'text',
    multiline: true,
  },
  {
    key: 'field_metas',
    label: 'Metas',
    type: 'text',
    multiline: true,
  },
  
];
