import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './assets/css/index.css';
import 'nprogress/nprogress.css';
import Routes from './Routes';
import { Snacks, AppLoader } from 'components';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppLoader>
        <Snacks />
        <Router>
          <Routes />
        </Router>
      </AppLoader>
    </ThemeProvider>
  )
}

export default App
