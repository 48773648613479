import React, { Fragment, useState } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { FormHelperText } from '@material-ui/core';

const FormDatePicker = props => {
  const {
    label,
    ...rest
  } = props;

  const [selectedDate, handleDateChange] = useState(rest.defaultValue);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <input
          name={`${rest.name}`}
          readOnly
          ref={rest.inputRef}
          type="hidden"
          value={selectedDate}
        />

        <DateTimePicker
          ampm={false}
          disabled={rest.disabled}
          error={rest.error}
          fullWidth
          inputVariant="outlined"
          label={label}
          onChange={handleDateChange}
          value={selectedDate}
        />
      </MuiPickersUtilsProvider>
      
      {rest.helperText && (
        <FormHelperText error>{rest.helperText}</FormHelperText>
      )}
    </Fragment>
  );
};

FormDatePicker.propTypes = {
  label: PropTypes.string,
};

export default FormDatePicker;
