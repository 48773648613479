import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import useStyles from './styles'
import { SearchInput } from 'components';

const ListToolbar = props => {
  const {
    className,
    search,
    createLabel,
    onCreateClick,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        {search ? (
          <SearchInput
            className={classes.searchInput}
            clickToSearch={search.clickToSearch}
            loading={search.loading || false}
            onSearch={search.onSearch}
            placeholder={search.placeholder || 'Search...'}
          />
        ) : null}

        <span className={classes.spacer} />
        
        {onCreateClick && (
          <Button
            color="primary"
            onClick={onCreateClick}
            variant="contained"
          >
            {createLabel}
          </Button>  
        )}
      </div>
    </div>
  );
};

ListToolbar.defaultProps = {
  search: null,
  createLabel: 'Create new',
  onCreateClick: null,
};

ListToolbar.propTypes = {
  className: PropTypes.string,
  createLabel: PropTypes.string,
  onCreateClick: PropTypes.func,
  search: PropTypes.shape({
    clickToSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    loading: PropTypes.bool,
  })
};

export default ListToolbar;
