import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
  },
  headerBox: {
    height: 68,
    marginBottom: theme.spacing(3),
  },
  headerFullWidth: {
    marginTop: '-24px',
    marginLeft: '-24px',
    marginRight: '-24px',
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: '0 1px 3px 0 rgba(63,63,68,0.15)',
    },
  },
  titlePaddingLeft: {
    paddingLeft: theme.spacing(5),
  },
  headerBackArrow: {
    position: 'absolute',
    left: 4,
    top: 8,
  },
  headerActions: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    '& > .MuiButtonBase-root + .MuiButtonBase-root': {
      marginLeft: '16px',
    },
    '& > .MuiCircularProgress-root:not(:last-child)': {
      marginRight: '16px',
    }
  },
}));
