import { createContext } from 'react';
import { decorate, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';
import { InputAgent } from 'agents';

export class InputField {
  item = null;
  items = [];
  pagination = null;

  find = task(async (id) => {
    const response = await InputAgent.find(id);
    runInAction(() => {
      this.item = response.data;
    });
  });

  all = task(async () => {
    const response = await InputAgent.all();
    return response.data.data;
  });

  fetch = task(async (page = 1) => {
    const response = await InputAgent.get(page);
    runInAction(() => {
      this.items = response.data.data;
      this.pagination = { ...response.data, data: null };
    });
  });

  updateInputField = task(async (data) => {
    await InputAgent.update(data)
  });

  reset = () => {
    this.fetch.setState({ state: 'pending' });
    this.items = [];
  };
}

decorate(InputField, {
  item: observable,
  items: observable,
  pagination: observable,
});

export default createContext(new InputField());
