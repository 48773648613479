import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from '@material-ui/core';

const FormGrid = ({ children }) => {
  return (
    <Grid
      container
      spacing={3}
    >
      {children}
    </Grid>
  );
}

FormGrid.defaultProps = {
};

FormGrid.propTypes = {
  children: PropTypes.node,
};

export default FormGrid;
