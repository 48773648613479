import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from 'components';
import { CopyToolbar } from '../../styles';

const DataToolbar = ({ onSearch, placeholder }) => {
  return (
    <CopyToolbar>
      <SearchInput
        onSearch={onSearch}
        placeholder={placeholder}
      />
    </CopyToolbar>
  );
};

DataToolbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default DataToolbar;
